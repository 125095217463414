import React, { useState, useEffect } from 'react'
import { Box, Grid, Heading, Paragraph, Text } from 'grommet'
import Number from '../../../components/Number'
import Loading from '../../../components/loading'
//import BudgetBar from "../../../components/budget-bar";
import { ButtonGroup, ButtonGroupItem } from '../../../components/button-group'

const StatisticsTemplate = props => {
  const hasFacebookStats = props.facebook?.budget > 0
  const hasGoogleStats = props.googleAds?.budget > 0

  const [showPan, setShowPan] = useState(false)

  useEffect(() => {
    setShowPan(hasFacebookStats ? 'FACEBOOK' : hasGoogleStats ? 'GOOGLE' : false)
  }, [hasFacebookStats, hasGoogleStats])

  const handlePanelClick = show => {
    if (showPan === show) setShowPan(false)
    else setShowPan(show)
  }

  return (
    <Box gap="large">
      <Box className="statistiques_con" gap="small" align="center">
        <Text>Le budget dépensé sur vos campagnes</Text>
        {!props.loading ? (
          <Box align="center">
            <Heading level="2" fill>
              <Number
                value={props.budget}
                abbreviation={true}
                style="currency"
                currency="eur"
                maximumSignificantDigits={3}
                size="big"
              />
            </Heading>
            {/* VisiretailApp issues #81
              <Box width="85vw" align="center">
                <BudgetBar
                  budget={props.forcastedBudget}
                  spendIn={[props.budget]}
                  budgetDisplay={true}
                />
              </Box>
            */}
          </Box>
        ) : (
          <Loading />
        )}
      </Box>
      <Box className="title">
        <Heading level="6" fill>
          STATISTIQUES DÉTAILLÉES{' '}
        </Heading>
        <Paragraph fill>
          Ces statistiques vous permettent de mieux connaître votre cible et les personnes interessées par vos produits.
        </Paragraph>
      </Box>
      <div className="statistiques_main">
        <div className="statistiques_tab">
          <ButtonGroup className="nav-tabs" style={{ top: '-85px' }}>
            {hasGoogleStats && (
              <ButtonGroupItem selected={showPan === 'GOOGLE'} onClick={() => handlePanelClick('GOOGLE')}>
                <img
                  src={require('../../../assets/images/ic-google.svg').default}
                  className="img-responsive"
                  alt="google"
                />
                GOOGLE
              </ButtonGroupItem>
            )}
            {hasFacebookStats && (
              <ButtonGroupItem selected={showPan === 'FACEBOOK'} onClick={() => handlePanelClick('FACEBOOK')}>
                <img
                  src={require('../../../assets/images/ic-facebook.svg').default}
                  className="img-responsive"
                  alt="facebook"
                />
                FACEBOOK
              </ButtonGroupItem>
            )}
          </ButtonGroup>
          <div className="tab-content">
            {
              {
                GOOGLE: hasGoogleStats && (
                  <Box animation="fadeIn" gap="large">
                    <Box gap="small">
                      <Text>Le budget dépensé sur Google</Text>
                      <Heading level="2" fill>
                        {props.loading ? (
                          <Loading />
                        ) : (
                          <Number
                            value={parseFloat(props.googleAds?.budget)}
                            abbreviation={true}
                            style="currency"
                            currency="eur"
                            maximumSignificantDigits={3}
                            size="big"
                          />
                        )}
                      </Heading>
                    </Box>
                    {props.googleAds?.impressions !== 0 && (
                      <Grid columns="medium" gap="medium">
                        <Box background="light-1" height="230px" round="5px" pad="large">
                          <div className="spent_top">
                            <span className="icon">
                              <img
                                src={require('../../../assets/images/statistics/google-ads/impressions.svg').default}
                                alt="impressions"
                                className="img-responsive"
                              />
                            </span>
                            <Heading level="3">
                              {props.loading ? (
                                <Loading />
                              ) : (
                                <Number
                                  value={parseInt(props.googleAds?.impressions)}
                                  abbreviation={true}
                                  maximumSignificantDigits={3}
                                />
                              )}
                            </Heading>
                          </div>
                          <div className="spent_des">
                            <Heading level="5">Impressions</Heading>
                            <p>Nombre d’affichage de votre publicité.</p>
                          </div>
                        </Box>
                        <Box background="light-1" height="230px" round="5px" pad="large">
                          <div className="spent_top">
                            <span className="icon">
                              <img
                                src={require('../../../assets/images/statistics/google-ads/clicks.svg').default}
                                alt="Clics"
                                className="img-responsive"
                              />
                            </span>
                            <Heading level="3" fill>
                              {props.loading ? (
                                <Loading />
                              ) : (
                                <Number
                                  value={parseInt(props.googleAds?.clicks)}
                                  abbreviation={true}
                                  maximumSignificantDigits={3}
                                />
                              )}
                            </Heading>
                          </div>
                          <div className="spent_des">
                            <Heading level="5">Clics</Heading>
                            <p>Nombre d’internautes qui ont cliqué sur la publicité. </p>
                          </div>
                        </Box>
                        <Box background="light-1" height="230px" round="5px" pad="large">
                          <div className="spent_top">
                            <Heading level="3" fill>
                              {props.loading ? (
                                <Loading />
                              ) : (
                                <Number
                                  value={parseFloat(props.googleAds?.clickThroughRate)}
                                  abbreviation={false}
                                  style={'percent'}
                                  maximumSignificantDigits={3}
                                />
                              )}
                            </Heading>
                          </div>
                          <div className="spent_des">
                            <Heading level="5">Taux de clics</Heading>
                            <p>Proportion d’internaute qui a cliqué sur la publicité après l’avoir vu.</p>
                          </div>
                        </Box>
                        <Box background="light-1" height="230px" round="5px" pad="large">
                          <div className="spent_top">
                            <span className="icon">
                              <img
                                src={require('../../../assets/images/statistics/google-ads/cost-per-click.svg').default}
                                alt="coût-moyen"
                                className="img-responsive"
                              />
                            </span>
                            <Heading level="3" fill>
                              {props.loading ? (
                                <Loading />
                              ) : (
                                <Number
                                  value={parseFloat(props.googleAds?.costPerClick)}
                                  abbreviation
                                  style={'currency'}
                                  currency={'eur'}
                                  maximumSignificantDigits={3}
                                />
                              )}
                            </Heading>
                          </div>
                          <div className="spent_des">
                            <Heading level="5">Coût moyen du clic</Heading>
                            <p>
                              Prix moyen payé pour un visiteur sur votre site / votre page suite à un clic sur la pub.
                            </p>
                          </div>
                        </Box>
                      </Grid>
                    )}
                  </Box>
                ),
                FACEBOOK: hasFacebookStats && (
                  <Box animation="fadeIn" gap="large">
                    <Box gap="small">
                      <Text>Le budget dépensé sur Facebook</Text>
                      <Heading level="2" fill>
                        {props.loading ? (
                          <Loading />
                        ) : (
                          <Number
                            value={parseFloat(props.facebook?.budget)}
                            abbreviation={true}
                            style="currency"
                            currency="eur"
                            maximumSignificantDigits={3}
                            size="big"
                          />
                        )}
                      </Heading>
                    </Box>
                    {props.facebook?.impressions !== 0 && (
                      <Grid columns="medium" gap="medium">
                        <Box background="light-1" height="230px" round="5px" pad="large">
                          <div className="spent_top">
                            <span className="icon">
                              <img
                                src={require('../../../assets/images/statistics/facebook/impressions.svg').default}
                                alt="impressions"
                                className="img-responsive"
                              />
                            </span>
                            <Heading level="3" fill>
                              {props.loading ? (
                                <Loading />
                              ) : (
                                <Number
                                  value={parseInt(props.facebook?.impressions)}
                                  abbreviation={true}
                                  maximumSignificantDigits={3}
                                />
                              )}
                            </Heading>
                          </div>
                          <div className="spent_des">
                            <Heading level="5">Impressions</Heading>
                            <p>Nombre d’affichage de votre publicité.</p>
                          </div>
                        </Box>
                        <Box background="light-1" height="230px" round="5px" pad="large">
                          <div className="spent_top">
                            <span className="icon">
                              <img
                                src={require('../../../assets/images/statistics/facebook/reach.svg').default}
                                alt="portée"
                                className="img-responsive"
                              />
                            </span>
                            <Heading level="3" fill>
                              {props.loading ? (
                                <Loading />
                              ) : (
                                <Number
                                  value={parseInt(props.facebook?.reach)}
                                  abbreviation={true}
                                  maximumSignificantDigits={3}
                                />
                              )}
                            </Heading>
                          </div>
                          <div className="spent_des">
                            <Heading level="5">Portée</Heading>
                            <p>La portée est l’audience effective de la campagne (le nombre d’internautes uniques) .</p>
                          </div>
                        </Box>
                        <Box background="light-1" height="230px" round="5px" pad="large">
                          <div className="spent_top">
                            <span className="icon">
                              <img
                                src={require('../../../assets/images/statistics/facebook/clicks.svg').default}
                                alt="Clics"
                                className="img-responsive"
                              />
                            </span>
                            <Heading level="3" fill>
                              {props.loading ? (
                                <Loading />
                              ) : (
                                <Number
                                  value={parseInt(props.facebook?.clicks)}
                                  abbreviation={true}
                                  maximumSignificantDigits={3}
                                />
                              )}
                            </Heading>
                          </div>
                          <div className="spent_des">
                            <Heading level="5">Clics</Heading>
                            <p>Nombre d’internautes qui ont cliqué sur la publicité. </p>
                          </div>
                        </Box>
                        <Box background="light-1" height="230px" round="5px" pad="large">
                          <div className="spent_top">
                            <Heading level="3" fill>
                              {props.loading ? (
                                <Loading />
                              ) : (
                                <Number
                                  value={parseFloat(props.facebook?.clickThroughRate)}
                                  abbreviation={false}
                                  style={'percent'}
                                  maximumSignificantDigits={3}
                                />
                              )}
                            </Heading>
                          </div>
                          <div className="spent_des">
                            <Heading level="5">Taux de clics</Heading>
                            <p>Proportion d’internaute qui a cliqué sur la publicité après l’avoir vu.</p>
                          </div>
                        </Box>
                        <Box background="light-1" height="230px" round="5px" pad="large">
                          <div className="spent_top">
                            <span className="icon">
                              <img
                                src={require('../../../assets/images/statistics/facebook/cost-per-click.svg').default}
                                alt="coût-moyen"
                                className="img-responsive"
                              />
                            </span>
                            <Heading level="3" fill>
                              {props.loading ? (
                                <Loading />
                              ) : (
                                <Number
                                  value={parseFloat(props.facebook?.costPerClick)}
                                  abbreviation={false}
                                  style={'currency'}
                                  currency={'eur'}
                                  maximumSignificantDigits={2}
                                />
                              )}
                            </Heading>
                          </div>
                          <div className="spent_des">
                            <Heading level="5">Coût moyen du clic</Heading>
                            <p>
                              Prix moyen payé pour un visiteur sur votre site / votre page suite à un clic sur la pub.
                            </p>
                          </div>
                        </Box>
                      </Grid>
                    )}
                  </Box>
                ),
              }[showPan]
            }
          </div>
        </div>
      </div>
    </Box>
  )
}

export default StatisticsTemplate
