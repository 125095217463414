import React, { createElement, useContext } from 'react'
import { Box, Button, CheckBox, ResponsiveContext, Text } from 'grommet'
import { FormDown, FormUp } from 'grommet-icons'
import { isString, map, upperCase } from 'lodash/fp'

import { GridLayout } from './GridLayout'

const SORT_DIRECTION_ICON = {
  DESC: FormDown,
  ASC: FormUp,
}

const Cell = ({ header, property, align, sortable, sort, onSort }) => {
  const content = isString(header) ? (
    <Text size="xsmall" color="dark-2" weight="bold" style={{ textTransform: 'uppercase' }}>
      {header}
    </Text>
  ) : (
    header
  )

  return (
    <Box direction="row" gap="small" justify={align}>
      {sort && sortable !== false ? (
        <Button
          style={{ whiteSpace: 'nowrap' }}
          label={content}
          icon={
            sort.property === property
              ? createElement(SORT_DIRECTION_ICON[upperCase(sort.direction)], {
                  size: 'small',
                })
              : null
          }
          reverse
          onClick={() => onSort(property)}
        />
      ) : (
        content
      )}
    </Box>
  )
}

export const Header = ({
  columns,
  isSelectable,
  totalSelect,
  totalData,
  totalCount,
  sort,
  style,
  data,
  primaryKey,
  select,
  onCheckAll,
  onSelectAll,
  onSort,
  ...props
}) => {
  const size = useContext(ResponsiveContext)

  const isAllSelected = data.length > 0 && data.every(d => select?.includes(d[primaryKey]))

  return (
    <Box gridArea="header" round={size !== 'small' ? '5px' : 'none'} {...props} {...style}>
      <GridLayout isSelectable={isSelectable} columns={columns} type="header">
        {isSelectable && (
          <CheckBox
            checked={isAllSelected}
            indeterminate={!isAllSelected && !data.every(d => !select?.includes(d[primaryKey]))}
            onChange={event => onCheckAll(event.target.checked)}
          />
        )}
        {map(column => <Cell key={column.property} sort={sort} {...column} onSort={onSort} />)(columns)}
      </GridLayout>

      {/*(isAllSelected && totalSelect < totalCount) && (
        <Box background="light-1" margin={{ top: 'medium' }} align="center">
          <Text textAlign="center">
            Les {totalSelect} établissements de cette page sont séléctionnés.{' '}
            <Button label={`Séléctionner vos ${totalCount} établissements`} color="brand" onClick={onSelectAll} />
          </Text>
        </Box>
      )*/}
    </Box>
  )
}

Header.defaultProps = {
  background: 'light-1',
  pad: {
    vertical: 'medium',
    horizontal: 'large',
  },
}
