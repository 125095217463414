import { MainNavigation, MobileMainNavigation } from 'components/navigation'
import { Box, ResponsiveContext } from 'grommet'
import { FC, useContext } from 'react'

export const MainLayout: FC = ({ children }) => {
  const size = useContext(ResponsiveContext)

  return (
    <>
      {size === 'small' ? <MobileMainNavigation /> : <MainNavigation />}
      <Box as="main">{children}</Box>
    </>
  )
}
