import React, { useState } from 'react'
import { Heading } from 'grommet'
import { Link } from 'react-router-dom'
import { map, filter } from 'lodash/fp'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Box } from 'grommet'
import { sortPointOfSalesStatisticsSelector } from '../selectors'
import Rank from '../../../components/rank'
//only works with create-react-app
import { ReactComponent as Search } from '../../../assets/images/ic-search.svg'

const PointOfSaleSearch = styled.div(({ theme }) => ({
  position: 'relative',
  svg: {
    position: 'absolute',
    left: '10px',
    bottom: '5px',
    width: '12px',
    zIndex: '100',
    fill: theme.global.colors.brand,
  },
}))

const StickyBox = styled(Box)({
  position: 'sticky',
  top: '0px',
})

export const PointOfSaleSearchStatistics = () => {
  const [search, setSearch] = useState('')

  const pointOfSales = useSelector(sortPointOfSalesStatisticsSelector)
  const filteredPointOfSales = filter(pointOfSale => pointOfSale.name.match(new RegExp(search, 'gi')))(pointOfSales)

  const handleSearch = e => {
    setSearch(e.target.value)
  }

  return (
    <StickyBox
      fill="horizontal"
      direction="row"
      pad={{ horizontal: 'large' }}
      justify="center"
      align="center"
      background="background"
      gap="xlarge"
    >
      <PointOfSaleSearch className="sale_search">
        <Search />
        <input
          id="suggestonInput"
          placeholder="Rechercher un point de vente..."
          className="form-control serch_txt"
          onChange={handleSearch}
          value={search}
          type="search"
        />
      </PointOfSaleSearch>
      <div className="pointsale_slider">
        <div className="slider-nav-thumbnails">
          {map(pointOfSale => (
            <div key={pointOfSale.id} style={{ minWidth: '350px' }}>
              <Link to={`/analytics/point-of-sales/${pointOfSale.id}`}>
                <div className="point_con">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Rank position={pointOfSale.position} />
                    <div style={{ marginLeft: 10 }}>
                      <Heading level="5" size="small">
                        {pointOfSale.name}
                      </Heading>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))(filteredPointOfSales)}
        </div>
      </div>
    </StickyBox>
  )
}
