import moment from 'moment'
import { isEmpty, find, map, sortBy } from 'lodash/fp'

export const canRefreshStatistics = lastCall =>
  isEmpty(lastCall) ||
  moment(lastCall)
    .add(4, 'h')
    .isBefore(moment())

export const getLastYear = () => ({
  since: moment()
    .startOf('year')
    .subtract(1, 'year')
    .format('YYYY-MM-DD'),
  until: moment()
    .endOf('year')
    .subtract(1, 'year')
    .format('YYYY-MM-DD'),
})

export const getLast6Months = () => ({
  since: moment()
    .startOf('month')
    .subtract(5, 'month')
    .format('YYYY-MM-DD'),
  until: moment().format('YYYY-MM-DD'),
})

export const getLastMonth = () => ({
  since: moment()
    .startOf('month')
    .subtract(1, 'month')
    .format('YYYY-MM-DD'),
  until: moment()
    .endOf('month')
    .subtract(1, 'month')
    .format('YYYY-MM-DD'),
})

export const getLastWeek = () => ({
  since: moment()
    .day(1)
    .subtract(1, 'week')
    .format('YYYY-MM-DD'),
  until: moment()
    .day(7)
    .subtract(1, 'week')
    .format('YYYY-MM-DD'),
})

export const getCustomDates = (since, until) =>
  moment(since) < moment() && {
    since: moment(since).format('YYYY-MM-DD'),
    until: moment(until).format('YYYY-MM-DD'),
  }

const fillMissingDays = (since, until) => {
  let result = []
  for (let d = new Date(since); d <= new Date(until); d.setDate(d.getDate() + 1)) {
    result.push({
      x: Date.parse(d),
      y: 0,
    })
  }
  return result
}

export const normalizeDays = (days, { since, until }) => {
  if (isEmpty(days)) return []

  const sortDays = sortBy('date')(days)
  return [
    ...fillMissingDays(since, sortDays[0].date),
    ...map(day => ({
      x: Date.parse(day.date),
      y: day.clicks,
    }))(sortDays),
    ...fillMissingDays(sortDays[sortDays.length - 1].date, until),
  ]
}
