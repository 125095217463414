import { Box, Text } from 'grommet'
import { Icon } from 'grommet-icons'
import { ReactElement } from 'react'

type Props = {
  icon: ReactElement<Icon>
  label: string
}

export const Tag = ({ label, icon }: Props) => (
  <Box
    direction="row"
    align="center"
    gap="small"
    background="white"
    pad={{ vertical: 'small', horizontal: 'medium' }}
    border={{ side: 'all', color: 'dark-3' }}
    round="small"
  >
    {icon}
    <Text weight="bold">{label}</Text>
  </Box>
)
