import { buildStoreChunk, Types } from 'redux-scc'

const alertsSchema = Types.reducer(
  Types.shape({
    [Types.wildcardKey()]: Types.shape({
      status: Types.string(),
      title: Types.string(),
      content: Types.string(),
    }),
  }),
)

export const { reducers, selectors, actions, resetAll } = buildStoreChunk('common-store', {
  alerts: alertsSchema,
})

const selectAlerts = selectors.alerts
const alertsActions = actions.alerts

export { selectAlerts, alertsActions }
