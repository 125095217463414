import {
  LOADING,
  LOADING_END,
  WHITE_LABELLING_GET_THEME,
  WHITE_LABELLING_GET_THEME_FULLFILLED,
  WHITE_LABELLING_GET_THEME_REJECTED,
} from './types'
import { apiWhiteLabel } from 'libs/axios'

const DEFAULT_HOSTNAMES = ['visiretail-dev.com', 'visiretail.io']

const loadFonts = async fonts => {
  fonts.forEach(async ({ name, url, descriptors }) => {
    const fontFace = new FontFace(name, `url(${url})`, descriptors)
    try {
      const loadedFont = await fontFace.load()
      document.fonts.add(loadedFont)
    } catch (e) {
      console.error(e)
    }
  })
}

const loadFavicon = faviconUrl => {
  const link = document.createElement('link')
  link.type = 'image/x-icon'
  link.rel = 'shortcut icon'
  link.href = faviconUrl
  document.getElementsByTagName('head')[0].appendChild(link)
}

const loadTitle = title => {
  document.title = title
}

const loadAssets = async ({ assets, metadata }) => {
  await Promise.all([loadFonts(assets.fonts), loadFavicon(metadata.favicon), loadTitle(metadata.title)])
}

export const getThemeByHostname = () => async (dispatch, getState) => {
  const hostname = window.location.hostname
  if (DEFAULT_HOSTNAMES.includes(hostname)) {
    const { assets, metadata } = getState().theme
    await loadAssets({ assets, metadata })
    return
  }

  dispatch({ type: WHITE_LABELLING_GET_THEME })
  try {
    dispatch({ type: LOADING })
    const { data } = await apiWhiteLabel.get(`/public/v1/hostnames/${hostname}/theme`)
    dispatch({
      type: WHITE_LABELLING_GET_THEME_FULLFILLED,
      payload: data,
    })
  } catch (e) {
    dispatch({ type: WHITE_LABELLING_GET_THEME_REJECTED, payload: e })
  } finally {
    const { assets, metadata } = getState().theme
    await loadAssets({ assets, metadata })
    dispatch({ type: LOADING_END })
  }
}
