import React from 'react'
import { Box, Grid, Text } from 'grommet'
import { useSelector } from 'react-redux'
import { selectLoadingStatistics } from '../storeChunk'
import { globalStatisticsForProductSelector } from '../selectors'
import Card from '../../../components/card'
import Number from '../../../components/Number'
import Loading from '../../../components/loading'

export const GlobalProductStatistics = ({ forProduct }) => {
  const loading = useSelector(selectLoadingStatistics)
  const statistics = useSelector(globalStatisticsForProductSelector(forProduct))

  return (
    <Grid columns="medium" gap="medium">
      <Box>
        <Card label="établissements actifs">
          {!loading ? (
            <Text size="xxlarge">
              {statistics?.companiesStatus.active}/{statistics?.companiesStatus.total}
            </Text>
          ) : (
            <Loading />
          )}
        </Card>
      </Box>
      <Box>
        <Card label="impressions">
          {!loading ? (
            <span>
              <Number value={statistics?.impressions} abbreviation />
            </span>
          ) : (
            <Loading />
          )}
        </Card>
      </Box>
      <Box>
        <Card label="clics">
          {!loading ? (
            <span>
              <Number value={statistics?.clicks} abbreviation />
            </span>
          ) : (
            <Loading />
          )}
        </Card>
      </Box>
      <Box>
        <Card label="conversions">
          {!loading ? (
            <span>
              <Number value={statistics?.conversions} abbreviation />
            </span>
          ) : (
            <Loading />
          )}
        </Card>
      </Box>
      <Box>
        <Card label="budget google" img={require('../../../assets/images/ic-google.svg').default} alt="google">
          {!loading ? (
            <span>
              <Number value={statistics?.googleAds.budget} abbreviation style="currency" currency="eur" />
            </span>
          ) : (
            <Loading />
          )}
        </Card>
      </Box>
      <Box>
        <Card label="budget facebook" img={require('../../../assets/images/ic-facebook.svg').default} alt="facebook">
          {!loading ? (
            <span>
              <Number value={statistics?.facebook.budget} abbreviation style="currency" currency="eur" />
            </span>
          ) : (
            <Loading />
          )}
        </Card>
      </Box>
      <Box>
        <Card label="coût du clic">
          {!loading ? (
            <span>
              <Number
                value={statistics?.costPerClick}
                abbreviation
                style="currency"
                currency="eur"
                maximumSignificantDigits={3}
              />
            </span>
          ) : (
            <Loading />
          )}
        </Card>
      </Box>
      <Box>
        <Card label="total dépensé" accent>
          {!loading ? (
            <span>
              <Number value={statistics?.budget} abbreviation style="currency" currency="eur" />
            </span>
          ) : (
            <Loading color="white" />
          )}
        </Card>
      </Box>
    </Grid>
  )
}
