import {
  WHITE_LABELLING_GET_THEME,
  WHITE_LABELLING_GET_THEME_FULLFILLED,
  WHITE_LABELLING_GET_THEME_REJECTED,
  RESET,
} from '../actions/types'
import { DEFAULT_ASSETS, DEFAULT_METADATA } from '../constants/theme'
import { merge } from 'lodash/fp'
import logoUrl from '../assets/images/visiretail-logo.svg'
import { DEFAULT_GROMMET_THEME } from 'libs/grommet'

const INITIAL_STATE = {
  name: 'visiretail',
  logoUrl,
  assets: DEFAULT_ASSETS,
  theme: DEFAULT_GROMMET_THEME,
  metadata: DEFAULT_METADATA,
  isLoading: false,
  isFetched: false,
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case WHITE_LABELLING_GET_THEME:
      return { ...state, isLoading: true }
    case WHITE_LABELLING_GET_THEME_FULLFILLED:
      const { styles, ...rest } = action.payload
      return merge(state, {
        ...rest,
        theme: merge(state.theme, styles),
        isLoading: false,
        isFetched: true,
      })
    case WHITE_LABELLING_GET_THEME_REJECTED:
      return { ...INITIAL_STATE, isLoading: false, isFetched: false }
    case RESET:
      return INITIAL_STATE
    default:
      return state
  }
}
