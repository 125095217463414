import React, { useContext } from "react";
import { Box, Paragraph, Heading, ResponsiveContext } from "grommet";
import { FormNext } from "grommet-icons";
import { isEmpty } from "lodash/fp";
import { PointOfSalesRowTemplate } from "./PointOfSalesRowTemplate";
import Loading from "../../../components/loading";
import styled from "styled-components";

import { LinkAnchor } from '../../../components'

const PointOfSaleWrapper = styled.div(({ theme }) => ({
  label: {
    background: theme.global.colors.brand,
    color: theme.global.colors['light-1'],
  },
}))

const PointOfSaleList = styled.ul(({ theme }) => ({
  'li:first-of-type': {
    border: `2px solid ${theme.global.colors.brand}`,
  },
}))

export const PointOfSalesListTemplate = ({ loading, pointOfSales }) => {
  const size = useContext(ResponsiveContext)

  return (
    <Box gap="large">
      <Box className="title">
        <Heading level="6" fill>
          TOP POINTS DE VENTE {size}
        </Heading>
      </Box>
      <Box gap="large" direction={size === 'small' ? 'column' : 'row'}>
        <Box width="50vw">
          <Paragraph>
            LE TOP permet de révéler vos points de vente les plus performants sur
            la période séléctionnée. Cette performance est évaluée selon le nombre
            de clics et le budget dépensé sur vos campagnes.
          </Paragraph>
        </Box>
        <Box fill gap="small">
          <PointOfSaleWrapper className="selling_list section">
            <label>Point de vente le plus performant</label>
            <PointOfSaleList>
              {!loading ? (
                !isEmpty(pointOfSales) ? (
                  pointOfSales.map(pointOfSale => (
                    <PointOfSalesRowTemplate
                      key={pointOfSale.id}
                      {...pointOfSale}
                    />
                  ))
                ) : (
                  <div
                    style={{ marginLeft: "10px", marginTop: "15px" }}
                    className="row"
                  >
                    <div className="col s12 m6">
                      <div className="card white darken-1">
                        <div className="card-content white-text">
                          <p>
                            Nous ne retrouvons aucun point de ventes rattaché à
                            votre compte.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <li>
                  <div className="sell_point_raw" style={{ textAlign: "center" }}>
                    <Loading />
                  </div>
                </li>
              )}
            </PointOfSaleList>
          </PointOfSaleWrapper>
          <LinkAnchor
            to="/analytics/network-overview"
            color="brand"
            label="Voir tous mes points de vente"
            icon={<FormNext size="small" color="brand" />}
            textAlign="end"
            reverse
          />
        </Box>
      </Box>
    </Box>
  );
  
}