import React, { Component } from 'react'
import { Heading } from 'grommet'
import DateRangePicker from 'react-daterange-picker'
import OutsideClickHandler from 'react-outside-click-handler'
import { Box } from 'grommet'

import { ButtonGroup, ButtonGroupItem } from '../../../components/button-group'
import styled from 'styled-components'
import 'react-daterange-picker/dist/css/react-calendar.css'
import moment from 'moment'
//only works with create-react-app
import { ReactComponent as Arrow } from '../../../assets/images/arrow.svg'

const DateRange = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'flex-end',
  marginTop: 6,
  label: {
    color: theme.global.colors.brand,
  },
  svg: {
    fill: theme.global.colors.brand,
    border: `1px solid #ccc`,
    borderRadius: `50%`,
    width: 25,
    height: 25,
    padding: 6,
  },
}))

const DateRangePickerWrapper = styled.div(({ theme }) => ({
  '.DateRangePicker__CalendarSelection, .DateRangePicker__LegendItemColor--selection, .DateRangePicker__CalendarSelection': {
    background: theme.global.colors.brand,
  },
  '.DateRangePicker__CalendarHighlight--single': {
    border: `1px solid ${theme.global.colors.brand}`,
  },
}))

const StickyBox = styled(Box)({
  position: 'sticky',
  top: '0px',
})

export default class DateNavBarTemplate extends Component {
  constructor(props) {
    super(props)

    this.state = {
      datePickerIsOpen: false,
      customDate: moment.range(moment(props.since), moment(props.until)),
      menu: props.range,
    }
  }

  activateMenu = name => {
    this.setState({
      ...this.state,
      menu: name,
    })
  }

  onSelect = dates => {
    this.props.onDateRangeSelect({
      range: 'custom',
      since: dates.start,
      until: dates.end,
    })
    this.setState({
      ...this.state,
      customDate: moment.range(moment(dates.start), moment(dates.end)),
    })
    this.togglePicker()
    this.activateMenu('custom')
  }

  setDatePickerState = state => {
    this.setState({
      ...this.state,
      datePickerIsOpen: state,
    })
  }

  togglePicker = () => {
    this.setDatePickerState(!this.state.datePickerIsOpen)
  }

  render() {
    return (
      <StickyBox
        fill="horizontal"
        background="light-1"
        direction="row"
        pad="large"
        justify="around"
        align="center"
        wrap
      >
        <ButtonGroup>
          <ButtonGroupItem
            disabled={this.props.loading}
            selected={this.state.menu === 'year'}
            onClick={() => {
              this.props.onDateRangeSelect({ range: 'year' })
              this.activateMenu('year')
            }}
          >
            L'an dernier
          </ButtonGroupItem>
          <ButtonGroupItem
            disabled={this.props.loading}
            selected={this.state.menu === 'half'}
            onClick={() => {
              this.props.onDateRangeSelect({ range: 'half' })
              this.activateMenu('half')
            }}
          >
            Les 6 derniers mois
          </ButtonGroupItem>
          <ButtonGroupItem
            disabled={this.props.loading}
            selected={this.state.menu === 'month'}
            onClick={() => {
              this.props.onDateRangeSelect({ range: 'month' })
              this.activateMenu('month')
            }}
          >
            Le mois dernier
          </ButtonGroupItem>
          <ButtonGroupItem
            disabled={this.props.loading}
            selected={this.state.menu === 'week'}
            onClick={() => {
              this.props.onDateRangeSelect({ range: 'week' })
              this.activateMenu('week')
            }}
          >
            La semaine dernière
          </ButtonGroupItem>
          <ButtonGroupItem
            disabled={this.props.loading}
            selected={this.state.menu === 'custom'}
            onClick={this.togglePicker}
          >
            personnalisé...
          </ButtonGroupItem>
        </ButtonGroup>
        <DateRange
          className="daterange"
          onClick={() => {
            this.setDatePickerState(true)
          }}
        >
          <div className="for_date">
            <Heading level="6">Début</Heading>
            <label className="from">{new Date(this.props.since).toLocaleDateString()}</label>
          </div>
          <Arrow />
          <div className="for_date">
            <Heading level="6">Fin</Heading>
            <label className="to">{new Date(this.props.until).toLocaleDateString()}</label>
          </div>
        </DateRange>

        <DateRangePickerWrapper
          style={{
            padding: '20px',
            display: this.state.datePickerIsOpen ? '' : 'none',
            backgroundColor: 'white',
          }}
          className="daterangepicker"
        >
          <OutsideClickHandler
            onOutsideClick={() => {
              this.setDatePickerState(false)
            }}
          >
            <DateRangePicker
              onSelect={this.onSelect}
              numberOfCalendars={2}
              selectionType="range"
              value={this.state.customDate}
              locale={'fr'}
              maximumDate={new Date()}
            />
          </OutsideClickHandler>
        </DateRangePickerWrapper>
      </StickyBox>
    )
  }
}
