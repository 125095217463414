import React from 'react'
import { Button } from 'grommet'
import { upperCase } from 'lodash/fp'
import styled from 'styled-components'

const StatusColor = {
  LOADING: 'default',
  COMPLETE: 'status-ok',
}

const ButtonWrapper = styled(Button)(({ theme, color }) => ({
  ':hover': {
    background: theme.global.colors[color],
  },
}))

export const StatusButton = ({ status, ...props }) => (
  <ButtonWrapper primary color={StatusColor[upperCase(status)]} disabled={status === 'idle'} {...props} />
)
