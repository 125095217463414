import { combineReducers } from "redux";
import user from "./user";
import statisticDates from "./statisticDates";
import loading from "./loading";
import theme from "./theme";
import resetPassword from "./resetPassword";
import statistics from "../modules/statistics";
import productModule from "../modules/product";
import common from "../modules/common";

export default combineReducers({
  statisticDates,
  loading,
  user,
  theme,
  resetPassword,
  ...statistics.reducers,
  ...productModule.reducers,
  ...common.reducers,
})
