import { ERRORS_MESSAGE } from 'i18n'
import create from 'zustand'

import { Notification } from './types'

const generateRandomId = (): string =>
  Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)

type NotificationStore = {
  notifications: Notification[]
  addNotification: (notification: Omit<Notification, 'id'>) => void
  dismissNotification: (id: string) => void
}

export const useNotificationStore = create<NotificationStore>(set => ({
  notifications: [],
  addNotification: notification =>
    set(state => ({
      notifications: [
        ...state.notifications,
        {
          ...notification,
          id: notification.code || generateRandomId(),
          message: notification.message || (notification.code && ERRORS_MESSAGE[notification.code]),
        },
      ],
    })),
  dismissNotification: id =>
    set(state => ({
      notifications: state.notifications.filter(notification => notification.id !== id),
    })),
}))
