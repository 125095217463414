import React, { useContext } from 'react'
import { Box, ResponsiveContext, TextInput } from 'grommet'
import { Search } from 'grommet-icons'

export const SearchBar = ({ style, onChange, ...props }) => {
  const size = useContext(ResponsiveContext)

  return (
    <Box gridArea="search" pad={{ horizontal: size === 'small' ? 'small' : 'none' }}>
      <TextInput plain={size === 'small'} onChange={(event) => onChange(event.target.value)} {...style} />
    </Box>
  )
}

SearchBar.defaultProps = {
  placeholder: 'Rechercher...',
  icon: <Search size="small" />,
  reverse: true,
}
