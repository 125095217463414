import React from 'react'
import styled from 'styled-components'

const DefaultRank = styled.span(({ theme }) => ({
  backgroundColor: theme.global.colors.brand,
}))

const Rank = ({ position }) => {
  return (
    <>
      {position <= 3 && position > 0 ? (
        <img
          src={require(`../assets/images/sell-postion-${position}.svg`).default}
          className="img-responsive number nbg"
          alt="#"
        />
      ) : (
        <DefaultRank className="number">{position}</DefaultRank>
      )}
    </>
  )
}

export default Rank
