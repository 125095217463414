import { Container } from "components/layouts";
import { Box, Text, Anchor, ResponsiveContext } from "grommet"
import { Heart } from "libs/icons";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { version } from '../../../package.json';

export const Footer = () => {
  const size = useContext(ResponsiveContext)

  if (size === 'small') {
    return null
  }

  return (
    <Box
      as="footer"
      height="xxsmall"
      direction="row"
      background="white"
      align="center"
      pad={{ horizontal: 'xlarge', vertical: 'small' }}
      margin={{ top: 'auto' }}
      justify="between"
    >
      <Container justify="between" direction={size === 'small' ? 'column' : 'row'}>
        <Text color="dark-200" size="small" weight={500}>Made with <Heart color="#ff5c5b" size={14} /> by Visiperf</Text>
        <Box direction="row" align="center" gap="medium">
          <Link to="/CGS">
            <Anchor as="span" size="small" color="dark-200" weight={500}>
              Conditions générales de ventes
            </Anchor>
          </Link>
          <Text size="small" color="dark-200" weight={500}>
            Version v{version}
          </Text>
        </Box>
      </Container>
    </Box>
  )
}
