import React, { createElement, useContext } from 'react'
import { Box, CheckBox, Collapsible, ResponsiveContext, Text } from 'grommet'
import { FormDown, FormUp } from 'grommet-icons'
import { includes, map } from 'lodash/fp'

import { GridLayout } from './GridLayout'
import { Divider } from '../elements/Divider'

const mapWithKey = map.convert({ cap: false })

const styledSelectedRow = {
  background: 'light-1',
  border: { color: 'brand' },
}

const Cell = ({ render, value, align, ...rest }) => (
  <Box align={align} {...rest}>
    {render ? createElement(render, { ...value, alignSelf: align }) : createElement(Text, {}, value)}
  </Box>
)

const Row = ({
  isSelectable,
  isExpandable,
  isSelected,
  columns,
  data,
  index,
  style,
  onCheck,
  onExpand,
  rowDetails,
  ...props
}) => {
  const size = useContext(ResponsiveContext)

  return (
    <Box
      background="white"
      gridArea="body"
      round={size !== 'small' ? '5px' : 'none'}
      {...props}
      {...style}
      {...(size !== 'small' && isSelected && styledSelectedRow)}
      onClick={() => onExpand(index)}
    >
      <GridLayout isSelectable={isSelectable} isExpandable={rowDetails} columns={columns} type="body">
        {isSelectable && <CheckBox checked={isSelected} onChange={event => onCheck(event.target.checked)} />}
        {map(column => (
          <Cell key={column.property} align={column.align} render={column.render} value={data[column.property]} onClick={() => isSelectable && onCheck(!isSelected)} />
        ))(columns)}
        {rowDetails && (
          <Box onClick={() => onExpand(index)}>
            {isExpandable ? <FormUp size="small" /> : <FormDown size="small" />}
          </Box>
        )}
      </GridLayout>
      {rowDetails && (
        <Collapsible open={isExpandable}>
          <Divider background="#e5e5E5" margin={{ vertical: 'medium' }} />
          {rowDetails(data)}
        </Collapsible>
      )}
    </Box>
  )
}

Row.defaultProps = {
  pad: { vertical: 'medium', horizontal: 'large' },
}

export const Body = ({
  data,
  select,
  gridColumns,
  columns,
  primaryKey,
  isSelectable,
  rowExpanded,
  onCheck,
  onExpand,
  rowDetails,
  style,
  ...props
}) => (
  <Box gridArea="body" {...props} {...style}>
    {mapWithKey((d, index) => (
      <Row
        key={d[primaryKey]}
        isSelectable={isSelectable}
        isExpandable={includes(index)(rowExpanded)}
        isSelected={includes(d[primaryKey])(select)}
        gridColumns={gridColumns}
        columns={columns}
        data={d}
        index={index}
        style={style?.row}
        rowDetails={rowDetails}
        onCheck={event => onCheck(event, d[primaryKey])}
        onExpand={onExpand}
      />
    ))(data)}
  </Box>
)

Body.defaultProps = {
  gap: 'xsmall',
}
