import { FormNextLink, FormPreviousLink, FormUp } from 'grommet-icons'

export const DEFAULT_GROMMET_THEME = {
  name: 'Visiretail v2',
  global: {
    colors: {
      default: '#CBD5E0',
      brand: '#8E54E9',
      'brand-dark': '#6E41B5',
      secondary: '#8E54E9',
      'secondary-dark': '#6E41B5',
      'accent-1': '#52BE80',
      'accent-2': '#30A060',
      'accent-3': '#F2994A',
      'neutral-1': '#D0C3F6',
      'neutral-2': '#DFA0B6',
      'neutral-3': '#7F9CF5',
      background: '#F7FAFC',
      background2: '#F7FAFC',
      'light-1': '#FFFFFF',
      'light-2': '#F7FAFC',
      'light-3': '#EDF2F7',
      'dark-1': '#1A202C',
      'dark-2': '#718096',
      'dark-3': '#A0AEC0',
      text: 'dark-700',
      control: 'dark-2',
      placeholder: 'dark-3',
      focus: 'dark-2',
      'status-error': '#EB5757',
      'status-critical': '#EB5757',
      'status-warning': '#F2994A',
      'status-ok': '#52BE80',
      'status-unknown': '#CCCCCC',
      'status-disabled': '#CCCCCC',
      'status-recommended': '#f6c100',
      'status-info': '#0057D2',
      'role-owner': '#52BE80',
      'role-admin': '#7F9CF5',
      'role-buyer': '#DFA0B6',
      'role-member': '#F2994A',

      'light-200': '#EDF2F7',
      'dark-200': '#A0AEC0',
      'dark-500': '#718096',
      'dark-700': '#273138',
    },
    elevation: {
      small: '0px 2px 11px rgba(0, 0, 0, 0.07)',
      medium: '0px 4px 10px rgba(25, 1, 52, 0.16)',
    },
    font: {
      family: 'Inter',
      size: '16px',
      height: '24px',
      color: 'default',
    },
    focus: {
      border: { size: '0px' },
      shadow: {
        size: '0px',
      },
    },
    edgeSize: {
      none: '0px',
      hair: '1px',
      xxsmall: '0.125rem',
      xsmall: '0.25rem',
      small: '0.5rem',
      medium: '1rem',
      large: '1.5rem',
      xlarge: '2.5rem',
      responsiveBreakpoint: 'small',
    },
    breakpoints: {
      small: {
        value: '768',
        edgeSize: {
          none: '0px',
          hair: '1px',
          xxsmall: '1px',
          xsmall: '0.125rem',
          small: '0.25rem',
          medium: '0.5rem',
          large: '1rem',
          xlarge: '1.5rem',
        },
      },
    },

    input: {
      padding: {
        vertical: '13px',
        horizontal: '16px',
      },
      weight: 'normal',
      extend: () => ({
        background: 'white',
      }),
    },
    hover: {
      background: 'light-2',
    },
  },
  accordion: {
    border: {
      size: '0px',
    },
  },
  icon: {
    size: {
      small: '16px',
      medium: '32px',
      large: '48px',
      xlarge: '64px',
    },
  },
  menu: {
    icons: {
      up: FormUp,
    },
  },
  anchor: {
    color: 'dark-2',
    textDecoration: 'none',
    fontWeight: 'normal',
    hover: {
      textDecoration: 'none',
      color: 'brand',
      extend: ({ theme }: any) => ({
        color: theme?.global?.colors?.brand,
        fill: theme?.global?.colors?.brand,
        stroke: theme?.global?.colors?.brand,
      }),
    },
    extend: ({ theme, active }: any) => ({
      fontSize: '14px',
      ...(active && {
        color: theme?.global?.colors?.brand,
      }),
    }),
  },
  heading: {
    color: 'dark-1',
    level: {
      '1': {
        small: {
          size: '2.375rem',
        },
        medium: {
          size: '2.125rem',
          height: '100%',
        },
        large: {
          size: '5.125rem',
        },
        xlarge: {
          size: '6.125rem',
        },
      },
      '2': {
        small: {
          size: '1.625rem',
        },
        medium: {
          size: '2.25rem',
        },
        large: {
          size: '3.125',
        },
        xlarge: {
          size: '4.125rem',
        },
      },
      '3': {
        small: {
          size: '0.75rem',
        },
        medium: {
          size: '1.5rem',
        },
        large: {
          size: '2.125rem',
        },
        xlarge: {
          size: '2.625rem',
        },
      },
      '4': {
        medium: {
          size: '1.25rem',
        },
        large: {
          size: '1.125',
        },
        xlarge: {
          size: '1.5rem',
        },
      },
      '5': {
        medium: {
          size: '1.125rem',
        },
      },
      '6': {
        medium: {
          size: '1.125rem',
        },
      },
    },
  },
  paragraph: {
    small: {
      size: '0.875rem',
    },
    medium: {
      size: '1rem',
    },
    large: {
      size: '1.375rem',
    },
    xlarge: {
      size: '1.625rem',
    },
    xxlarge: {
      size: '2.125rem',
    },
  },
  text: {
    xsmall: {
      size: '0.75rem',
    },
    small: {
      size: '0.875rem',
    },
    medium: {
      size: '1rem',
    },
  },
  button: {
    border: {
      radius: '5px',
    },
    size: {
      medium: {
        border: {
          radius: '5px',
        },
      },
      small: {
        border: {
          radius: '4px',
        },
      },
    },
    default: {
      color: 'text',
      border: undefined,
    },
    primary: {
      background: { color: 'brand' },
      border: undefined,
      color: 'white',
      font: { weight: '500' },
      padding: {
        horizontal: '16px',
        vertical: '10px',
      },
    },
    secondary: {
      border: { color: 'dark-500', width: '2px' },
      font: { weight: '500' },
      color: 'dark-500',
      padding: {
        horizontal: '16px',
        vertical: '8px',
      },
    },
    disabled: {
      opacity: 0.6,
      extend: {
        cursor: 'not-allowed',
      },
      secondary: {
        background: { color: 'transparent' },
        color: 'white',
      },
    },
    hover: {
      primary: {
        background: { color: 'brand-dark' },
        color: 'white',
      },
      secondary: {
        color: 'dark-700',
        border: { color: 'dark-700' },
      },
    },
  },
  checkBox: {
    extend: () => `
      align-items: flex-start;
    `,
    check: {
      extend: ({ theme, checked, indeterminate }: any) => `
        ${(checked || indeterminate) && `background-color: ${theme.global.colors.brand};`}
      `,
    },
    icon: {
      size: '1rem',
      extend: 'stroke: white;',
    },
    hover: { border: { color: 'brand' } },
    size: '1rem',
    color: 'brand',
  },
  radioButton: {
    check: {
      color: 'white',
      extend: ({ theme, children }: any) => ({
        backgroundColor: children && theme?.global?.colors?.brand,
        marginTop: '2px',
      }),
    },
    hover: { border: { color: 'brand' } },
    size: '1rem',
    color: 'brand',
    gap: 'medium',
    container: {
      extend: {
        alignItems: 'start',
      },
    },
  },
  select: {
    control: {
      open: {
        borderBottom: 'none',
        borderRadius: '4px 4px 0px 0px',
        boxShadow: '-0px 0px 2px rgba(0,0,0,0.20)',
      },
      extend: ({ theme }: any) => ({
        background: 'white',
        borderColor: theme?.global?.colors?.default,
      }),
    },
    container: {
      extend: ({ theme }: any) => ({
        padding: theme?.global?.edgeSize?.small,
        border: `1px solid ${theme?.global?.colors?.default}`,
        borderTop: 'none',
        borderRadius: '0px',
        button: {
          padding: theme?.global?.edgeSize?.medium,
          borderRadius: '5px',
          background: 'white'
        },
      }),
    },
    icons: {
      color: 'dark-2',
    },
  },
  tabs: {
    extend: ({ theme }: any) => ({
      boxShadow: `inset 0px -1px 0px 0px ${theme?.global?.colors['dark-3']}`,
    }),
    header: {
      extend: {
        flexWrap: 'nowrap',
        maxWidth: 'none',
      },
    },
    gap: 'large',
  },
  tab: {
    active: {
      color: 'brand-dark',
    },
    border: {
      size: '3px',
      color: 'none',
      active: {
        color: 'brand-dark',
      },
    },
    margin: {
      left: '0px',
      bottom: '0px',
    },
    pad: {
      bottom: 'medium',
    },
    extend: {
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
    },
  },
  formField: {
    border: {
      color: 'dark-3',
      side: 'all',
    },
    label: {
      color: 'dark-3',
      margin: {
        horizontal: 'none',
      },
    },
    margin: {
      bottom: 'medium',
    },
    round: '4px',
  },
  pagination: {
    button: {
      color: 'dark-2',
      active: {
        background: {
          color: 'light-3',
        },
        color: 'brand',
      },
    },
    icons: {
      next: FormNextLink,
      previous: FormPreviousLink,
    },
  },
}

export const RECOMMENDED_BUTTON = {
  button: {
    primary: {
      background: { color: 'status-recommended' },
      color: 'white',
    },
    hover: {
      primary: {
        background: { color: '#826600' },
        color: 'white',
      },
    },
  },
}
