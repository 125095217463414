import { buildStoreChunk, Types } from 'redux-scc'
import { statusActionSchema } from '../../helpers/statusAction'

const displayType = Types.shape({
  value: Types.any(),
  display: Types.string(),
})

const periodType = Types.shape({
  startsAt: Types.string(),
  stopsAt: Types.string(),
})

const productSchema = Types.reducer(
  Types.shape({
    id: Types.string(),
    image: Types.string(),
    title: Types.string(),
    description: Types.string(),
    family: displayType,
    price: displayType,
    adBudget: displayType,
    type: displayType,
    duration: displayType,
    salesPeriod: periodType,
    launchPeriod: periodType,
    conversionTypes: Types.arrayOf(
      Types.shape({
        type: Types.string(),
        href: Types.string(),
      }),
    ),
    tags: Types.arrayOf(
      Types.shape({
        icon: Types.string(),
        label: Types.string(),
      }),
    ),
    supports: Types.arrayOf(
      Types.shape({
        icon: Types.string(),
        label: Types.string(),
      }),
    ),
    specificities: Types.arrayOf(
      Types.shape({
        title: Types.string(),
        content: Types.string(),
        image: Types.string(),
      }),
    ),
    performanceInsights: Types.arrayOf(
      Types.shape({
        icon: Types.string(),
        label: Types.string(),
        content: Types.string(),
      }),
    ),
  }),
)

export const { reducers, selectors, actions, resetAll } = buildStoreChunk('product-store', {
  productStatus: statusActionSchema,
  product: productSchema,
  leadStatus: statusActionSchema,
})

export const selectProductStatus = selectors.productStatus
export const selectProduct = selectors.product
export const selectLeadStatus = selectors.leadStatus

export const productStatusActions = actions.productStatus
export const productActions = actions.product
export const leadStatusActions = actions.leadStatus
