//General
export const RESET = 'reset'
export const LOADING = 'loading'
export const LOADING_END = 'loading_end'
//Auth
export const AUTH_CHILDREN = 'auth_children'
export const AUTH_USER = 'auth_user'
export const AUTH_ERROR = 'auth_error'
export const AUTH_GET_ME = 'auth_get_me'
export const AUTH_USER_LOGOUT = 'auth_user_logout'
//User
export const AUTH_GET_ONE_USER = 'auth_get_one_user'
export const AUTH_GET_ONE_USER_FULLFILLED = 'auth_get_one_user_fulfilled'
export const AUTH_GET_ONE_USER_REJECTED = 'auth_get_one_user_rejected'
//Groups
export const SERENDIPITY_GET_POS_STATS = 'serendipity_get_pos_stats'
export const SERENDIPITY_GET_POS_STATS_FULLFILLED = 'serendipity_get_pos_stats_fulfilled'
export const SERENDIPITY_GET_POS_STATS_REJECTED = 'serendipity_get_pos_stats_rejected'
export const SERENDIPITY_GET_POS_STATS_BY_PRODUCT_ID = 'serendipity_get_pos_stats_by_product_id'
export const SERENDIPITY_GET_POS_STATS_BY_PRODUCT_ID_FULLFILLED = 'serendipity_get_pos_stats_by_product_id_fulfilled'
export const SERENDIPITY_GET_POS_STATS_BY_PRODUCT_ID_REJECTED = 'serendipity_get_pos_stats_by_product_id_rejected'
export const SORT_GROUP_STATISTICS = 'sort_group_statistics'
export const SORT_GROUP_STATISTICS_BY_PRODUCT = 'sort_group_statistics_by_product'
//Campaigns
export const SERENDIPITY_GET_CAMPAIGNS_KPI = 'serendipity_get_campaigns_kpi'
export const SERENDIPITY_GET_CAMPAIGNS_KPI_FULLFILLED = 'serendipity_get_campaigns_kpi_fulfilled'
export const SERENDIPITY_GET_CAMPAIGNS_KPI_REJECTED = 'serendipity_get_campaigns_kpi_rejected'
export const SERENDIPITY_GET_CAMPAIGNS_KPI_BY_PRODUCT_ID = 'serendipity_get_campaigns_kpi_by_product_id'
export const SERENDIPITY_GET_CAMPAIGNS_KPI_BY_PRODUCT_ID_FULLFILLED =
  'serendipity_get_campaigns_kpi_by_product_id_fulfilled'
export const SERENDIPITY_GET_CAMPAIGNS_KPI_BY_PRODUCT_ID_REJECTED =
  'serendipity_get_campaigns_kpi_by_product_id_rejected'

export const SERENDIPITY_GET_ALL_CAMPAIGNS_KPI = 'serendipity_get_all_campaigns_kpi'
export const SERENDIPITY_GET_ALL_CAMPAIGNS_KPI_FULLFILLED = 'serendipity_get_all_campaigns_kpi_fulfilled'
export const SERENDIPITY_GET_ALL_CAMPAIGNS_KPI_REJECTED = 'serendipity_get_all_campaigns_kpi_rejected'
//StatisticDates
export const STATS_GET_DATES = 'stats_get_dates'
export const STATS_GET_DATES_REJECTED = 'stats_get_dates_rejected'
//Products
export const SERENDIPITY_GET_PRODUCTS_SOON = 'serendipity_get_products_soon'
export const SERENDIPITY_GET_PRODUCTS_SOON_FULLFILLED = 'serendipity_get_products_soon_fulfilled'
export const SERENDIPITY_GET_PRODUCTS_REJECTED = 'serendipity_get_products_rejected'
export const SERENDIPITY_GET_PRODUCTS_NOW = 'serendipity_get_products_now'
export const SERENDIPITY_GET_PRODUCTS_NOW_FULLFILLED = 'serendipity_get_products_now_fulfilled'
export const SERENDIPITY_GET_ONE_PRODUCT = 'serendipity_get_one_product'
export const SERENDIPITY_GET_ONE_PRODUCT_FULLFILLED = 'serendipity_get_one_product_fulfilled'
export const SERENDIPITY_GET_ONE_PRODUCT_REJECTED = 'serendipity_get_one_product_rejected'
export const RESET_SELECTED_PRODUCT = 'reset_selected_product'
//Facebook
export const FACEBOOK_GET_ACCOUNTS_KPI = 'facebook_get_accounts_kpi'
export const FACEBOOK_GET_ACCOUNTS_KPI_FULLFILLED = 'facebook_get_accounts_kpi_fulfilled'
export const FACEBOOK_GET_ACCOUNTS_KPI_REJECTED = 'facebook_get_accounts_kpi_rejected'

export const FACEBOOK_GET_ACCOUNTS_KPI_AGE = 'facebook_get_accounts_age_kpi'
export const FACEBOOK_GET_ACCOUNTS_KPI_AGE_FULLFILLED = 'facebook_get_accounts_kpi_age_fulfilled'
export const FACEBOOK_GET_ACCOUNTS_KPI_AGE_REJECTED = 'facebook_get_accounts_kpi_age_rejected'

export const FACEBOOK_GET_ACCOUNTS_KPI_GENDER = 'facebook_get_accounts_gender_kpi'
export const FACEBOOK_GET_ACCOUNTS_KPI_GENDER_FULLFILLED = 'facebook_get_accounts_kpi_gender_fulfilled'
export const FACEBOOK_GET_ACCOUNTS_KPI_GENDER_REJECTED = 'facebook_get_accounts_kpi_gender_rejected'
//Adwords
export const ADWORDS_GET_CAMPAIGN_KPI = 'adwords_get_campaign_kpi'
export const ADWORDS_GET_CAMPAIGN_KPI_FULLFILLED = 'adwords_get_campaign_kpi_fullfilled'
export const ADWORDS_GET_CAMPAIGN_KPI_REJECTED = 'adwords_get_campaign_kpi_rejected'
//Global KPI
export const SERENDIPITY_GET_GLOBAL_STATS = 'serendipity_get_global_stats'
export const SERENDIPITY_GET_GLOBAL_STATS_FULLFILLED = 'serendipity_get_global_stats_fulfilled'
export const SERENDIPITY_GET_GLOBAL_STATS_REJECTED = 'serendipity_get_global_stats_rejected'
export const SERENDIPITY_GET_CLICK_DATE_STATS = 'serendipity_get_click_date_stats'
export const SERENDIPITY_GET_CLICK_DATE_STATS_FULLFILLED = 'serendipity_get_click_date_stats_fulfilled'
export const SERENDIPITY_GET_CLICK_DATE_STATS_REJECTED = 'serendipity_get_click_date_stats_rejected'
//Cart
export const CART_GET_BY_PRODUCT = 'cart_get_by_product'
export const CART_GET_BY_PRODUCT_FULLFILLED = 'cart_get_by_product_fullfilled'
export const CART_GET_BY_PRODUCT_REJECTED = 'cart_get_by_product_rejected'
export const CART_GET_BY_USER = 'cart_get_by_user'
export const CART_GET_BY_USER_FULLFILLED = 'cart_get_by_user_fullfilled'
export const CART_GET_BY_USER_REJECTED = 'cart_get_by_user_rejected'
export const CART_POST = 'cart_post'
export const CART_POST_FULLFILLED = 'cart_post_fullfilled'
export const CART_POST_REJECTED = 'cart_post_rejected'
export const CART_DELETE = 'cart_delete'
export const CART_DELETE_FULLFILLED = 'cart_delete_fullfilled'
export const CART_DELETE_REJECTED = 'cart_delete_rejected'
export const CART_PATCH = 'cart_patch'
export const CART_PATCH_FULLFILLED = 'cart_patch_fullfilled'
export const CART_PATCH_REJECTED = 'cart_patch_rejected'
export const CART_GET_NUMBER_PRODUCTS = 'cart_get_number_products'
export const CART_GET_NUMBER_PRODUCTS_FULLFILLED = 'cart_get_number_products_fullfilled'
export const CART_GET_NUMBER_PRODUCTS_REJECTED = 'cart_get_number_products_rejected'
export const CART_ADD_PRODUCT = 'cart_add_product'
export const CART_ADD_PRODUCT_FULLFILLED = 'cart_add_product_fullfilled'
export const CART_ADD_PRODUCT_REJECTED = 'cart_add_product_rejected'
//Payment
export const PAYMENT_POST = 'payment_post'
export const PAYMENT_POST_FULLFILLED = 'payment_post_fullfilled'
export const PAYMENT_POST_REJECTED = 'payment_post_rejected'
//WhiteLabelling
export const WHITE_LABELLING_GET_THEME = 'white_labelling_get_theme'
export const WHITE_LABELLING_GET_THEME_FULLFILLED = 'white_labelling_get_theme_fullfilled'
export const WHITE_LABELLING_GET_THEME_REJECTED = 'white_labelling_get_theme_rejected'
//Lead
export const PRODUCT_LEAD_SEND = 'product_lead_send'
export const PRODUCT_LEAD_SEND_FULLFILLED = 'product_lead_send_fullfilled'
export const PRODUCT_LEAD_SEND_REJECTED = 'product_lead_send_rejected'
//Reset Password
export const ASK_RESET_PASSWORD_FULLFILLED = 'ask_reset_password_fullfilled'
export const ASK_RESET_PASSWORD_REJECTED = 'ask_reset_password_rejected'
export const RESET_PASSWORD_FULLFILLED = 'reset_password_fullfilled'
export const RESET_PASSWORD_REJECTED = 'reset_password_rejected'
