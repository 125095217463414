import React, { useState } from 'react'
import { Box, Button } from 'grommet'
import { Heading } from 'grommet'
import ProductReportItem from '../../../container/productReport/ProductReportItem'
import Loading from '../../../components/loading'
import { slice } from 'lodash/fp'

const LIMITED_VIEW_PRODUCT_SIZE = 3

const ProductReportListTemplate = ({ items, fetching }) => {
  const [showMoreProduct, setShowMoreProduct] = useState(false)

  const firstItems = slice(0)(LIMITED_VIEW_PRODUCT_SIZE)(items)
  const moreItems = slice(LIMITED_VIEW_PRODUCT_SIZE)(items?.length)(items)

  return fetching ? (
    <center>
      <Loading />
    </center>
  ) : (
    <Box gap="medium">
      <Box>
        {items?.length === 0 ? (
          <center>
            <Heading level="5">Pas de campagnes pour cette periode.</Heading>
          </center>
        ) : (
          <Box gap="medium">
            {firstItems.map((item, i) => (
              <ProductReportItem key={i} item={item} />
            ))}
          </Box>
        )}
      </Box>
      {items?.length > LIMITED_VIEW_PRODUCT_SIZE && (
        <Box gap="medium">
          {showMoreProduct && (
            <ul>
              {moreItems.map((item, i) => (
                <ProductReportItem key={i} item={item} />
              ))}
            </ul>
          )}
          <Button
            onClick={() => {
              setShowMoreProduct(!showMoreProduct)
            }}
            label={!showMoreProduct ? 'Afficher toutes les campagnes' : 'Afficher moins de campagnes'}
            primary
          />
        </Box>
      )}
    </Box>
  )
}

export default ProductReportListTemplate
