import { buildStoreChunk, Types } from 'redux-scc'
import moment from 'moment'

const lastCallSchema = Types.reducer(Types.string())

const statisticsDatesSchema = Types.reducer(
  Types.shape({
    range: Types.string('custom'),
    since: Types.string(
      moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD'),
    ),
    until: Types.string(moment().format('YYYY-MM-DD')),
  }),
)

const loadingSchema = Types.reducer(Types.boolean())

const statsGlobalType = {
  impressions: Types.number(),
  clicks: Types.number(),
  conversions: Types.number(),
  budget: Types.number(),
  forcastedBudget: Types.number(),
  costPerClick: Types.number(),
  costPerConversion: Types.number(),
  reach: Types.number(),
  clickThroughRate: Types.number(),
}

const globalType = Types.shape({
  ...statsGlobalType,
  companiesStatus: Types.shape({
    active: Types.number(),
    total: Types.number(),
  }),
  facebook: Types.shape(statsGlobalType),
  googleAds: Types.shape(statsGlobalType),
})

const companyInfoType = {
  id: Types.string(),
  name: Types.string(),
  position: Types.number(),
}

const productInfoType = {
  id: Types.string(),
  name: Types.string(),
  family: Types.string(),
  image: Types.string(),
  launchPeriod: Types.shape({
    startsAt: Types.string(),
    stopsAt: Types.string(),
  }),
}

const companiesInProductType = {
  ...companyInfoType,
  global: globalType,
}

const productsInCompanyType = {
  ...productInfoType,
  global: globalType,
}

const companiesType = Types.shape({
  ...companiesInProductType,
  products: Types.shape({
    [Types.wildcardKey()]: Types.shape(productsInCompanyType),
  }),
})

const productsType = Types.shape({
  ...productsInCompanyType,
  companies: Types.shape({
    [Types.wildcardKey()]: Types.shape(companiesInProductType),
  }),
})

const statisticsSchema = Types.reducer(
  Types.shape({
    global: globalType,
    companies: Types.shape({
      [Types.wildcardKey()]: companiesType,
    }),
    products: Types.shape({
      [Types.wildcardKey()]: productsType,
    }),
  }),
)

const daysType = Types.arrayOf(
  Types.shape({
    date: Types.string(),
    clicks: Types.number(),
    impressions: Types.number(),
  }),
)

const dailyClickSchema = Types.reducer(
  Types.shape({
    global: Types.shape({
      days: daysType,
    }),
    companies: Types.shape({
      [Types.wildcardKey()]: Types.shape({
        days: daysType,
      }),
    }),
  }),
)

export const { reducers, selectors, actions, resetAll } = buildStoreChunk('statistics-store', {
  lastCall: lastCallSchema,
  statisticsDates: statisticsDatesSchema,
  loadingStatistics: loadingSchema,
  statistics: statisticsSchema,
  loadingDailyClick: loadingSchema,
  dailyClick: dailyClickSchema,
})

export const selectLastCall = selectors.lastCall
export const selectStatisticsDates = selectors.statisticsDates
export const selectLoadingStatistics = selectors.loadingStatistics
export const selectStatistics = selectors.statistics
export const selectLoadingDailyClick = selectors.loadingDailyClick
export const selectDailyClick = selectors.dailyClick

export const lastCallActions = actions.lastCall
export const statisticsDatesActions = actions.statisticsDates
export const loadingStatisticsActions = actions.loadingStatistics
export const statisticsActions = actions.statistics
export const loadingDailyClickActions = actions.loadingDailyClick
export const dailyClickActions = actions.dailyClick
