import React, { useContext } from 'react'
import { Grid, ResponsiveContext } from 'grommet'

export const ResponsiveGrid = ({ children, layout, ...props }) => {
  const size = useContext(ResponsiveContext)

  return (
    <Grid {...(layout[size] || layout.default || layout)} {...props}>
      {children}
    </Grid>
  )
}
