import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import ProductReportItemTemplate from '../../templates/v1/productReport/ProductReportItemTemplate'

class ProductReportItem extends Component {
  render() {
    return <ProductReportItemTemplate item={this.props.item} isMinClicks={this.props.item.isMinClicks} />
  }
}

function mapStateToProps(state) {
  return {}
}

export default compose(connect(mapStateToProps, {}))(ProductReportItem)
