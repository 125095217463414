import { useAuth0 } from '@auth0/auth0-react'
import { getPointOfSalesHasMinManagerRole } from 'api/visiretail/v2/point-of-sales'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { RouteObject, useNavigate, useRoutes } from 'react-router-dom'

import { protectedRoutes } from './protected'
import { publicRoutes } from './public'

export const AppRoutes = () => {
  const { user, isAuthenticated } = useAuth0()
  const { data: pointOfSales } = useQuery(['point-of-sales', { minRole: 'manager' }], () => getPointOfSalesHasMinManagerRole(''))

  const navigate = useNavigate()

  useEffect(() => {
    if (pointOfSales?.data.some(p => !p.paymentMethod) && user?.['https://auth.visiperf.dev/logins_count'] <= 1 && !localStorage.getItem('visiretail:onboarding')) {
      navigate('/onboarding')
      localStorage.setItem('visiretail:onboarding', '1')
    }
  }, [pointOfSales])

  const routes: RouteObject[] = [...(isAuthenticated ? protectedRoutes : []), ...publicRoutes]

  return useRoutes(routes)
}
