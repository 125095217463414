import { RESET, STATS_GET_DATES } from '../actions/types'
import moment from 'moment/moment'

const INITIAL_STATE = {
  type: 'custom',
  startDate: moment()
    .subtract(7, 'days')
    .format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case STATS_GET_DATES:
      return {
        ...state,
        ...action.payload,
      }
    case RESET:
      return INITIAL_STATE
    default:
      return state
  }
}
