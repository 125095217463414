import React from 'react'
import { useSelector } from 'react-redux'
import { selectLoadingStatistics } from '../storeChunk'
import { campaignsStatisticsForUserSelector, campaignsStatisticsSelector } from '../selectors'
import ProductReportListTemplate from '../../../templates/v1/productReport/ProductReportListTemplate'
import CampaignOverviewListTemplate from '../../../templates/v1/campaigns/CampaignOverviewListTemplate'

export const CampaignsListStatistics = ({ forUser }) => {
  const loading = useSelector(selectLoadingStatistics)
  const campaigns = useSelector(
    forUser === 'me' ? campaignsStatisticsSelector : campaignsStatisticsForUserSelector(forUser),
  )

  return (
    <>
      {forUser === 'me' ? (
        <CampaignOverviewListTemplate campaigns={campaigns} loading={loading} />
      ) : (
        <ProductReportListTemplate items={campaigns} fetching={loading} />
      )}
    </>
  )
}
