import { Auth0Context, Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { AUHT0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DEFAULT_SCOPE, AUTH0_DOMAIN } from 'config';
import React, { PropsWithChildren, useEffect } from 'react'
// import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

const HOSTNAME: Record<string, string> = {
  'dev.digitalfactory-dominos.com': 'XSvjUi4GZd4cPmE3sM8bbWDOFwTan3tz',
  'www.burgerfactoryccep.com': 'NVYd9JJ2tAwKwpDsGE8K4UPeO1O2g7Vz',
  'digitalfactory-dominos.com': 'GNQPCF2KNQhou5ZbrDg38vx4a8yYudqr',
  'www.mapubdigitale-ecf.fr': 'htnFZVZtRBOzwhqmrd6u4I7UNWT0wUi2',
  'mediaretail.io': '4nWLDMSC087kNwZsRKHqu8PtSoGKrpCm',
  'pub-digitales.provalliance.com': 'l8d7hmHSYQeLJGtNGbQv8VIv5RACtOon'
}

const deferred = (() => {
  const props: any = {};
  props.promise = new Promise((resolve) => props.resolve = resolve);
  return props;
})();

export const getAccessToken = async () => {
  const getToken = await deferred.promise;
  return getToken();
} 

type Props = {}

export const Auth0 = ({ children }: PropsWithChildren<Props>) => {  
  // const { metadata, isFetched } = useSelector(
  //   (state: any) => state.theme
  // );

  const navigate = useNavigate()

  const onRedirectCallback = (state: any) => {
    navigate(state?.returnTo || '/catalogs')
  }

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={HOSTNAME[window.location.hostname.toString()] || AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={AUHT0_AUDIENCE}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      advancedOptions={{ defaultScope: AUTH0_DEFAULT_SCOPE }}
    >
 
      <Auth0Context.Consumer>
        {({ getAccessTokenSilently }) => {
          deferred.resolve(getAccessTokenSilently)
          return (
            <Auth0Consumer>
              {children}
            </Auth0Consumer>
          )
        }}
      </Auth0Context.Consumer>
    </Auth0Provider>
  )
}

const Auth0Consumer = ({ children }: PropsWithChildren<any>) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect()
    }
  }, [isLoading, isAuthenticated])

  if (!isAuthenticated) {
    return null
  }

  return children
}