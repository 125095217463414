import React from 'react'
import { Heading, Text } from 'grommet'
import { Link } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import Number from '../../../components/Number'
import Rank from '../../../components/rank'
import styled from 'styled-components'

const CampaignLink = styled.span(({ theme }) => ({
  backgroundColor: `${theme.global.colors.brand} !important`,
  '&:hover': {
    backgroundColor: `${theme.global.colors.brandDark} !important`,
  },
}))

export const PointOfSalesRowTemplate = ({ id, name, position, global }) => (
  <IntlProvider locale="fr">
    <li>
      <Link to={`/analytics/point-of-sales/${id}`}>
        <div className="sell_point_raw">
          <div className="sell_info">
            <Rank position={position} />
            <div className="sell_des">
              <Heading level="6">{name}</Heading>
            </div>
          </div>
          <div className="sell_type">
            <ul>
              <li>
                <Heading level="6">Impressions</Heading>
                <Text color="dark-1">
                  <Number value={global.impressions} abbreviation maximumSignificantDigits={3} />
                </Text>
              </li>
              <li>
                <Heading level="6">Clics</Heading>
                <Text color="dark-1">
                  <Number value={global.clicks} abbreviation maximumSignificantDigits={3} />
                </Text>
              </li>
              <li>
                <Heading level="6">Conversions</Heading>
                <Text color="dark-1">
                  <Number
                    value={global.conversions}
                    abbreviation={true}
                    maximumSignificantDigits={3}
                    notAvailable={global.conversions === 0}
                  />
                </Text>
              </li>
              <li>
                <Heading level="6">Budget</Heading>
                <Text color="accent-1">
                  <Number
                    value={global.budget}
                    abbreviation
                    style="currency"
                    currency="eur"
                    maximumSignificantDigits={3}
                  />
                </Text>
              </li>
              <li>
                <Heading level="6">Coût de la conversion</Heading>
                <Text color="accent-1">
                  <Number
                    value={global.costPerConversion}
                    abbreviation
                    style="currency"
                    currency="eur"
                    maximumSignificantDigits={3}
                    notAvailable={global.costPerConversion === 0}
                  />
                </Text>
              </li>
            </ul>
          </div>
          <CampaignLink className="sell_arrow" />
        </div>
      </Link>
    </li>
  </IntlProvider>
)
