import React from 'react'
import { Box, Heading, Text, Image, Meter } from 'grommet'
import Number from '../../../components/Number'
import { Link } from 'react-router-dom'
import BudgetBar from '../../../components/budget-bar'
import CampaignAdvance from '../../../components/campaign-advance'

const CampaignItem = ({ id, name, family, launchPeriod, global }) => (
  <Box background="light-1" pad="large" round="5px" border={{ side: 'top', size: '4px', color: 'brand' }}>
    <Link to={`/product/${id}/campaign`}>
      <Box direction="row" align="center" justify="between">
        <Box>
          <Text color="brand">{family}</Text>
          <Heading level="2" size="small">
            {name}
          </Heading>
        </Box>
        <Box direction="row" gap="medium" align="center">
          <CampaignAdvance start={launchPeriod.startsAt} end={launchPeriod.stopsAt} title="Avancée" />
          <Box width="200px">
            <Box className="ovelay_right" fill style={{ height: 20 }}>
              <Box className="social_data" fill>
                <Box className="progress_main google" fill>
                  <BudgetBar budget={global.budget} spendIn={[global.googleAds.budget, global.facebook.budget]} />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box direction="row" gap="small" align="center">
            <Image src={require('../../../assets/images/ic-google.svg').default} alt="google" width="24px" />
            <Image src={require('../../../assets/images/ic-facebook.svg').default} alt="facebook" width="24px" />
          </Box>
        </Box>
      </Box>
      <div className="overlay_list">
        <ul>
          <li>
            <span className="ico">
              <img
                src={require('../../../assets/images/ic-impressions.svg').default}
                alt="impressions"
                className="img-responsive"
              />
            </span>
            <span className="value">
              <Number value={parseInt(global.impressions)} abbreviation={true} maximumSignificantDigits={3} />
            </span>
            <Heading level="6">impressions</Heading>
          </li>
          <li>
            <span className="ico">
              <img
                src={require('../../../assets/images/ic-clics.svg').default}
                alt="clics"
                className="img-responsive"
              />
            </span>
            <span className="value">
              <Number value={parseInt(global.clicks)} abbreviation={true} maximumSignificantDigits={3} />
            </span>
            <Heading level="6">clics</Heading>
          </li>
          <li>
            <span className="ico">
              <img
                src={require('../../../assets/images/ic-conversions.svg').default}
                alt="conversions"
                className="img-responsive"
              />
            </span>
            <span className="value">
              <Number
                value={parseInt(global.conversions)}
                abbreviation={true}
                maximumSignificantDigits={3}
                notAvailable={global.conversions === 0}
              />
            </span>
            <Heading level="6">conversions</Heading>
          </li>
          <li>
            <span className="ico">
              <img src={require('../../../assets/images/ic-euro.svg').default} alt="euro" className="img-responsive" />
            </span>
            <span className="value">
              <Number
                value={parseFloat(global.budget)}
                abbreviation={true}
                style="currency"
                currency="eur"
                maximumSignificantDigits={3}
              />
            </span>
            <Heading level="6">budget dépensé</Heading>
          </li>
          <li>
            <span className="ico">
              <img src={require('../../../assets/images/ic-euro.svg').default} alt="euro" className="img-responsive" />
            </span>
            <span className="value">
              <Number
                value={parseFloat(global.costPerClick)}
                abbreviation={true}
                style="currency"
                currency="eur"
                maximumSignificantDigits={3}
              />
            </span>
            <Heading level="6">coût du clic</Heading>
          </li>
        </ul>
      </div>
    </Link>
  </Box>
)

export default CampaignItem
