import { Footer } from "shared";
import { Box, Grommet } from "grommet"
import { Notifications } from "libs/notifications";
import { getThemeByHostname } from "old/actions/theme";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AppRoutes } from "routes"
import { Auth0 } from "libs/auth0";

const App = () => {
  const { isLoading, theme } = useSelector((state: any) => state.theme);

  const dispatch = useDispatch()
  const { pathname } = useLocation()

  useEffect(() => {
    dispatch(getThemeByHostname())
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  if (isLoading) {
    return null
  }

  return (
    <Grommet theme={theme}>
      <Notifications />
      <Box height={{ min: '100vh' }}>
        <AppRoutes />
        <Footer />
      </Box>
    </Grommet>
  )
}

export default App
