import React from 'react'
import { Box, Markdown as GrommetMarkdown, Paragraph, Text } from 'grommet'
import { FormCheckmark } from 'grommet-icons'
import { merge } from 'lodash/fp'

const withList = WrappedComponent => ({ children, as }) => (
  <Box as={as} gap="medium" margin={{ vertical: 'small' }}>
    {children.map((child, index) => (
      <Box key={index} as="li" direction="row" gap="large">
        <Box
          margin={{ vertical: '2px' }}
          round="full"
          border={{ color: 'brand' }}
          pad="small"
          width="20px"
          height="20px"
          justify="center"
          align="center"
        >
          <WrappedComponent position={index + 1} />
        </Box>
        <Text>{children.length > 1 ? child.props.children : <Paragraph fill>{child.props.children}</Paragraph>}</Text>
      </Box>
    ))}
  </Box>
)

const ListItem = withList(() => <FormCheckmark size="small" color="brand" />)

const OrderedListItem = withList(({ position }) => (
  <Text size="10px" color="brand" weight={500}>
    {position}
  </Text>
))

const HEADING_PROPS = {
  size: 'small',
  fill: true,
  margin: { vertical: 'small' },
}

const MARKDOWN_COMPONENTS = {
  div: {
    component: Box,
    props: {
      gap: 'medium',
    },
  },
  p: {
    props: {
      fill: true,
    },
  },
  h1: {
    props: HEADING_PROPS,
  },
  h2: {
    props: HEADING_PROPS,
  },
  h3: {
    props: {
      ...HEADING_PROPS,
      size: 'medium',
    },
  },
  ul: {
    component: ListItem,
    props: {
      as: 'ul',
    },
  },
  ol: {
    component: OrderedListItem,
    props: {
      as: 'ol',
    },
  },
  a: {
    props: {
      color: 'brand',
      style: {
        textDecoration: 'underline',
      },
    },
  },
  img: {
    props: {
      style: {
        width: 'max-content',
      },
    },
  },
}

export const Markdown = ({ content, components = {}, ...props }) =>
  content ? (
    <GrommetMarkdown components={merge(MARKDOWN_COMPONENTS, components)} {...props}>
      {content}
    </GrommetMarkdown>
  ) : null
