import React from 'react'
import { Grid } from 'grommet'
import { map } from 'lodash/fp'

const DEFAULT_COLUMN_WIDTH = '1fr'
const SELECTABLE_COLUMN_WIDTH = '1rem'
const EXPANDABLE_COLUMN_WIDTH = '1rem'

const buildGrid = (isSelectable, isExpandable, columns, type) => [
  ...((isSelectable && [SELECTABLE_COLUMN_WIDTH]) || []),
  ...map(({ size }) => size?.[type] || size || DEFAULT_COLUMN_WIDTH)(columns),
  ...((isExpandable && [EXPANDABLE_COLUMN_WIDTH]) || []),
]

export const GridLayout = ({ children, columns, isSelectable, isExpandable, type }) => (
  <Grid columns={buildGrid(isSelectable, isExpandable, columns, type)} gap="1rem" align="center">
    {children}
  </Grid>
)
