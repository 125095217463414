import { createSelector } from 'reselect'
import { sortBy, take, pick, omit, values } from 'lodash/fp'
import { selectDailyClick, selectStatistics, selectStatisticsDates } from './storeChunk'
import { normalizeDays } from './utils'

export const globalStatisticsSelector = createSelector(selectStatistics, ({ global }) => global)

export const globalStatisticsForUserSelector = companyId =>
  createSelector(selectStatistics, ({ companies }) => companies && companies[companyId])

export const globalStatisticsForProductSelector = productId =>
  createSelector(selectStatistics, ({ products }) => products && products[productId]?.global)

const detailedStatistics = pick(['budget', 'forcastedBudget', 'facebook', 'googleAds'])

export const detailedStatisticsSelector = createSelector(selectStatistics, ({ global }) => detailedStatistics(global))

export const detailedCompanyStatisticsForUserSelector = companyId =>
  createSelector(selectStatistics, ({ companies }) => companies && detailedStatistics(companies[companyId]?.global))

export const normalizedDailyClickSelector = createSelector(
  selectDailyClick,
  selectStatisticsDates,
  ({ global }, statisticsDates) => normalizeDays(global.days, statisticsDates),
)

export const normalizedDailyClickForUserSelector = companyId =>
  createSelector(
    selectDailyClick,
    selectStatisticsDates,
    ({ companies }, statisticsDates) => companies && normalizeDays(companies[companyId]?.days, statisticsDates),
  )

const sortPointOfSalesByPosition = sortBy('position')

export const sortPointOfSalesStatisticsSelector = createSelector(selectStatistics, ({ companies }) =>
  sortPointOfSalesByPosition(companies),
)

export const sortPointOfSalesStatisticsForProductSelector = productId =>
  createSelector(
    selectStatistics,
    ({ products }) => products && sortPointOfSalesByPosition(products[productId]?.companies),
  )

export const topPointOfSaleStatisticsSelector = limit => createSelector(sortPointOfSalesStatisticsSelector, take(limit))

export const productInfoSelector = productId =>
  createSelector(selectStatistics, ({ products }) => products && omit(['global', 'companies'])(products[productId]))

export const campaignsStatisticsSelector = createSelector(
  selectStatistics,
  ({ products }) => products && values(products),
)

export const campaignsStatisticsForUserSelector = companyId =>
  createSelector(selectStatistics, ({ companies }) => companies && values(companies[companyId]?.products))
