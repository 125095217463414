import React from 'react'
import { Box, Heading, Grid, Image, Text } from 'grommet'
import moment from 'moment'
import Number from '../../../components/Number'
import defaultImage from '../../../assets/images/productCard/not-available-image.svg'
import CampaignAdvance from '../../../components/campaign-advance'

const ProductReportItemTemplate = ({ item, isMinClicks }) => {
  const clickColorClass = isMinClicks ? 'status-critical' : 'status-ok'

  const { name, image, launchPeriod, global } = item

  return (
    <Box background="light-2" direction="row" gap="large" round="5px" wrap>
      <Box width="300px" round="5px">
        <Image src={image || defaultImage} alt={name} fit="cover" />
      </Box>

      <Box direction="row" align="center" pad="large" gap="large" flex="grow">
        <Box gap="medium" width={{ min: '270px' }}>
          <CampaignAdvance start={launchPeriod.startsAt} end={launchPeriod.stopsAt} />
          <Heading level="6">{name}</Heading>
          <Text color="dark-2">
            {moment(launchPeriod.startsAt).format('DD/MM/YYYY')}{' '}
            {launchPeriod.stopsAt && `- ${moment(launchPeriod.stopsAt).format('DD/MM/YYYY')}`}
          </Text>
        </Box>
        <Grid columns="small" gap="medium" fill>
          <Box alignSelf="center" align="center" gap="small">
            <Image src={require('../../../assets/images/ic-impressions.svg').default} alt="impressions" />
            <Text size="large">
              <Number value={parseFloat(global.impressions)} abbreviation={true} maximumSignificantDigits={3} />
            </Text>
            <Heading level="6">impressions</Heading>
          </Box>
          <Box alignSelf="center" align="center" gap="small">
            <Image
              src={require(`../../../assets/images/${isMinClicks ? 'ic-clics-red.svg' : 'ic-clics-green.svg'}`).default}
              alt="clics"
            />
            <Text size="large" color={clickColorClass}>
              <Number value={parseFloat(global.clicks)} abbreviation={true} maximumSignificantDigits={3} />
            </Text>
            <Heading level="6" color={clickColorClass}>
              clics
            </Heading>
          </Box>
          <Box alignSelf="center" align="center" gap="small">
            <Image
              src={require('../../../assets/images/ic-conversions.svg').default}
              alt="conversions"
              className="img-responsive"
            />
            <Text size="large">
              <Number
                value={parseFloat(global.conversions)}
                abbreviation={true}
                maximumSignificantDigits={3}
                notAvailable={global.conversions === 0}
              />
            </Text>
            <Heading level="6">conversions</Heading>
          </Box>
          <Box alignSelf="center" align="center" gap="small">
            <Image src={require('../../../assets/images/ic-euro.svg').default} alt="euro" />
            <Text size="large">
              <Number
                value={parseFloat(global.budget)}
                abbreviation={true}
                style="currency"
                currency="eur"
                maximumSignificantDigits={3}
              />
            </Text>
            <Heading level="6">budget dépensé</Heading>
          </Box>
          <Box alignSelf="center" align="center" gap="small">
            <Image src={require('../../../assets/images/ic-euro.svg').default} alt="euro" />
            <Text size="large">
              <Number
                value={parseFloat(global.costPerClick)}
                abbreviation={true}
                style="currency"
                currency="eur"
                maximumSignificantDigits={3}
              />
            </Text>
            <Heading level="6">coût du clic</Heading>
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

export default ProductReportItemTemplate
