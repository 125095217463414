import { render } from "react-dom";
import "./old/assets/css/style.css";
import "./old/assets/css/responsive.css";
import App from './App'
import registerServiceWorker from "./registerServiceWorker";
import { AppProvider } from "providers/app";

import 'libs/dayjs'
import 'libs/moment'
import { Auth0 } from "libs/auth0";

render(
  <AppProvider>
    <Auth0>
      <App />
    </Auth0>
  </AppProvider>,
  document.getElementById('root'),
)

registerServiceWorker()
