import { visicoreV2InstanceApi, VisicoreV2Response } from '.'
import { PurchaseOrder } from './type'

const baseURL = '/payment-request-transfer'

type PostPaymentRequestTransferPayload = {
  pricingId: string
  beneficiaryIds: string[]
  buyerId: string
}

export const createPaymentRequestTransfer = async (
  payload: PostPaymentRequestTransferPayload,
): Promise<VisicoreV2Response<PurchaseOrder>> => {
  return await visicoreV2InstanceApi.post(`${baseURL}`, payload)
}
