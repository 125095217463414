import { includes, isEqual } from 'lodash/fp'
import { Types } from 'redux-scc'

const StatusAction = {
  IDLE: 'idle',
  LOADING: 'loading',
  COMPLETE: 'complete',
  FAILED: 'failed',
}

export const statusActionSchema = Types.reducer(Types.string(StatusAction.IDLE))

export const completeOrFailed = status => includes(status)([StatusAction.COMPLETE, StatusAction.FAILED])

export const loadingOrComplete = status => includes(status)([StatusAction.LOADING, StatusAction.COMPLETE])

export const isIdle = isEqual(StatusAction.IDLE)

export const isLoading = isEqual(StatusAction.LOADING)

export const isComplete = isEqual(StatusAction.COMPLETE)

export const isFailed = isEqual(StatusAction.FAILED)

export default StatusAction
