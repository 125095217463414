import { Product, visicoreV2InstanceApi, VisicoreV2Response } from '.'

const baseURL = '/products'

type GetProductById = {
  productId: string
}

export const getProductById = async (params: GetProductById): Promise<VisicoreV2Response<Product>> => {
  return await visicoreV2InstanceApi.get(`${baseURL}/${params.productId}`)
}
