import React from 'react'
import { Link } from 'react-router-dom'
import { map, isEmpty } from 'lodash/fp'
import { useSelector } from 'react-redux'
import { selectLoadingStatistics } from '../storeChunk'
import { sortPointOfSalesStatisticsSelector, sortPointOfSalesStatisticsForProductSelector } from '../selectors'
import Loading from '../../../components/loading'
import Rank from '../../../components/rank'
import Number from '../../../components/Number'
import styled from 'styled-components'

const CampaignLink = styled(Link)(({ theme }) => ({
  backgroundColor: `${theme.global.colors.brand} !important`,
  '&:hover': {
    backgroundColor: `${theme.global.colors.brandDark} !important`,
  },
}))

export const PointOfSalesListStatistics = ({ forProduct }) => {
  const loading = useSelector(selectLoadingStatistics)
  const pointOfSales = useSelector(
    forProduct === 'me' ? sortPointOfSalesStatisticsSelector : sortPointOfSalesStatisticsForProductSelector(forProduct),
  )

  return (
    <div className="mescon_list">
      <table>
        <thead>
          <tr>
            <th>
              <button onClick={() => {}}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <i>
                  <img
                    src={require('../../../assets/images/play-button.svg').default}
                    alt="#"
                    className="img-responsive"
                  />
                </i>
              </button>
            </th>
            <th>
              <button onClick={() => {}}>
                nom
                <i>
                  <img
                    src={require('../../../assets/images/play-button.svg').default}
                    alt="#"
                    className="img-responsive"
                  />
                </i>
              </button>
            </th>
            <th>
              <button onClick={() => {}}>
                impressions
                <i>
                  <img
                    src={require('../../../assets/images/play-button.svg').default}
                    alt="#"
                    className="img-responsive"
                  />
                </i>
              </button>
            </th>
            <th>
              <button onClick={() => {}}>
                clics
                <i>
                  <img
                    src={require('../../../assets/images/play-button.svg').default}
                    alt="#"
                    className="img-responsive"
                  />
                </i>
              </button>
            </th>
            <th>
              <button onClick={() => {}}>
                conversions
                <i>
                  <img
                    src={require('../../../assets/images/play-button.svg').default}
                    alt="#"
                    className="img-responsive"
                  />
                </i>
              </button>
            </th>
            <th>
              <button onClick={() => {}}>
                budget
                <i>
                  <img
                    src={require('../../../assets/images/play-button.svg').default}
                    alt="#"
                    className="img-responsive"
                  />
                </i>
              </button>
            </th>
            <th>
              <button onClick={() => {}}>
                coût de la conversion
                <i>
                  <img
                    src={require('../../../assets/images/play-button.svg').default}
                    alt="#"
                    className="img-responsive"
                  />
                </i>
              </button>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!loading ? (
            !isEmpty(pointOfSales) ? (
              map(pointOfSale => (
                <tr key={pointOfSale.id}>
                  <td>
                    <Rank position={pointOfSale.position} />
                  </td>
                  <td>
                    <Link to={`/analytics/point-of-sales/${pointOfSale.id}`}>{pointOfSale.name}</Link>
                  </td>
                  <td>
                    <Number value={pointOfSale.global.impressions} abbreviation maximumSignificantDigits={3} />
                  </td>
                  <td>
                    <Number value={pointOfSale.global.clicks} abbreviation maximumSignificantDigits={3} />
                  </td>
                  <td>
                    <Number
                      value={pointOfSale.global.conversions}
                      abbreviation
                      maximumSignificantDigits={3}
                      notAvailable={pointOfSale.global.conversions === 0}
                    />
                  </td>
                  <td>
                    <Number
                      value={pointOfSale.global.budget}
                      abbreviation
                      style="currency"
                      currency="eur"
                      maximumSignificantDigits={3}
                    />
                  </td>
                  <td>
                    <Number
                      value={pointOfSale.global.costPerConversion}
                      abbreviation
                      style="currency"
                      currency="eur"
                      maximumSignificantDigits={3}
                      notAvailable={pointOfSale.global.costPerConversion === 0}
                    />
                  </td>
                  <td>
                    <CampaignLink to={`/analytics/point-of-sales/${pointOfSale.id}`}></CampaignLink>
                  </td>
                </tr>
              ))(pointOfSales)
            ) : (
              <tr>
                <td style={{ height: '80px', textAlign: 'center' }} colSpan={8}>
                  <div className="card-content white-text">
                    <p>Nous ne retrouvons aucun point de ventes rattaché à votre compte.</p>
                  </div>
                  {/*<div className="card-action">
                      <a
                        className={"teal-text"}
                        href={""}
                        onClick={() => this.props.getPOS()}
                      >
                        Rafraichir
                      </a>
                    </div>*/}
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td style={{ height: '80px', textAlign: 'center' }} colSpan={8}>
                <Loading />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
