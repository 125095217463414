import { Box, Heading, Text } from 'grommet'
import ContentLoader from 'react-content-loader'

type Item = {
  id: string
  title: string
  sub?: string
}

type Props = {
  title: string
  items: Item[]
  currentItem: string
  onSelect: (id: string) => void
}

export const AsideSelector = ({ title, items, currentItem, onSelect }: Props) => {
  const isItemSelected = (id: string) => id === currentItem

  return (
    <Box gap="xlarge">
      <Heading level={5} size="small" color="dark-200" margin={{ left: 'large' }}>
        {title}
      </Heading>
      <Box as="nav" gap="small">
        {items.map(item => (
          <Box
            key={item.id}
            style={{
              boxSizing: isItemSelected(item.id) ? 'content-box' : 'border-box',
            }}
            background={isItemSelected(item.id) ? 'brand' : ''}
            pad={{
              horizontal: 'large',
              vertical: 'medium',
              left: isItemSelected(item.id) ? '48px' : '',
            }}
            margin={{ left: isItemSelected(item.id) ? '-24px' : '' }}
            round={{ size: 'small' }}
            onClick={() => onSelect(item.id)}
          >
            <Text color={isItemSelected(item.id) ? 'white' : ''} weight={500}>
              {item.title}
            </Text>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export const AsideSelectorSkeleton = () => {
  return (
    <ContentLoader
      speed={2}
      viewBox="0 0 350 350"
      backgroundColor="#dee4ea"
      foregroundColor="#edf2f7"
      title="Chargement..."
    >
      <rect x="24" y="24" rx="4" ry="4" width="250" height="12" />
      <rect x="0" y="88" rx="4" ry="4" width="350" height="60" />
      <rect x="0" y="160" rx="4" ry="4" width="350" height="60" />
      <rect x="0" y="232" rx="4" ry="4" width="350" height="60" />
    </ContentLoader>
  )
}
