import { visicoreV2InstanceApi, Catalog, VisicoreV2Response, Tag, Product } from '.'

const baseURL = '/catalogs'

export const getCatalogs = async (): Promise<VisicoreV2Response<Catalog[]>> => {
  return await visicoreV2InstanceApi.get(baseURL)
}

type GetProductTagsByCatalogIdParams = {
  catalogId: string
}

export const getProductTagsByCatalogId = async (
  params: GetProductTagsByCatalogIdParams,
): Promise<VisicoreV2Response<Tag[]>> => {
  return await visicoreV2InstanceApi.get(`${baseURL}/${params.catalogId}/products/tags`)
}

type GetProductsByCatalogId = {
  catalogId: string
  queryParams: string
}

export const getProductsByCatalogId = async (
  params: GetProductsByCatalogId,
): Promise<VisicoreV2Response<Product[]>> => {
  return await visicoreV2InstanceApi.get(`${baseURL}/${params.catalogId}/products?${params.queryParams}`)
}
