import { Box, BoxProps } from 'grommet'
import { FC } from 'react'

const MAX_WIDTH = '1980px'

type Props = BoxProps & {}

export const Container: FC<Props> = ({ children, ...props }) => (
  <Box
    direction="row"
    align="center"
    width={{ max: MAX_WIDTH }}
    margin={{ horizontal: 'auto' }}
    fill="horizontal"
    {...props}
  >
    {children}
  </Box>
)
