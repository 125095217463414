import React from 'react'
import { useSelector } from 'react-redux'
import DetailedStatisticsTemplate from '../../../templates/v1/pointOfSales/DetailedStatisticsTemplate'
import { selectLoadingStatistics } from '../storeChunk'
import { detailedStatisticsSelector, detailedCompanyStatisticsForUserSelector } from '../selectors'

export const DetailedStatistics = ({ forUser }) => {
  const loading = useSelector(selectLoadingStatistics)
  const statistics = useSelector(
    forUser === 'me' ? detailedStatisticsSelector : detailedCompanyStatisticsForUserSelector(forUser),
  )

  return <DetailedStatisticsTemplate loading={loading} {...statistics} />
}
