import { parseQueryParams } from 'libs/query-params'
import { PointOfSale, visicoreV2InstanceApi, VisicoreV2Response } from '.'

const baseURL = '/point-of-sales'

type GetPointOfSalesParams = {
  query: string
}

export const getPointOfSales = async ({ query }: GetPointOfSalesParams): Promise<VisicoreV2Response<PointOfSale[]>> => {
  return await visicoreV2InstanceApi.get(`${baseURL}?${query}`)
}

export const getPointOfSalesHasMinManagerRole = async (cursor: string): Promise<VisicoreV2Response<PointOfSale[]>> => {
  return await getPointOfSales({ query: parseQueryParams({ minRole: 'manager', cursor }) })
}

export const getPointOfSalesHasMinBuyerRole = async (query: string, cursor: string, productId: string): Promise<VisicoreV2Response<PointOfSale[]>> => {
  return await getPointOfSales({ query: parseQueryParams({ minRole: 'buyer', query, cursor, productId }) })
}

export const getPointOfSalesId = async (query: any): Promise<VisicoreV2Response<string[]>> => {
  return await visicoreV2InstanceApi.get(`${baseURL}/id?${parseQueryParams(query)}`)
}

export const initializePaymentMethod = async (pointOfSaleId: string): Promise<VisicoreV2Response<{ clientSecret: string }>> => {
  return await visicoreV2InstanceApi.post(`${baseURL}/${pointOfSaleId}/payment-method`)
}

export const retrievePaymentIntent = async (pointOfSaleId: string, failureID: string): Promise<VisicoreV2Response<{ clientSecret: string }>> => {
  return await visicoreV2InstanceApi.post(`${baseURL}/${pointOfSaleId}/payment`, { failureID })
}