import React from 'react'
import { Box, Text } from 'grommet'

export const Chip = ({ color, label, size, weight, ...props }) => (
  <Box {...props}>
    <Text color={color} weight={weight} size={size} style={{ textTransform: 'uppercase' }}>
      {label}
    </Text>
  </Box>
)

Chip.defaultProps = {
  alignSelf: 'start',
  background: 'dark-3',
  color: 'light-1',
  weight: 'bold',
  round: '2px',
  size: 'xsmall',
  pad: {
    vertical: 'xsmall',
    horizontal: 'small',
  },
}
