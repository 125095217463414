import styled from 'styled-components'

export const ButtonGroup = styled.div({
  display: 'flex',
  flex: 1,
})

export const ButtonGroupItem = styled.button(({ theme, selected }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 10,
  flex: 1,
  border: '1px solid',
  textTransform: 'uppercase',
  fontSize: 12,
  fontWeight: 500,
  background: selected ? theme.global.colors['light-1'] : theme.global.colors['light-2'],
  color: selected ? theme.global.colors.brand : theme.global.colors['dark-2'],
  borderColor: selected ? theme.global.colors.brand : theme.global.colors['dark-2'],
  transition: ' all 0.25s ease 0s',
  '+ button': {
    borderLeftColor: selected ? theme.global.colors.brand : '',
  },
  '&:not(:last-child)': {
    borderRight: 'none',
  },
  '&:disabled': {
    background: selected ? theme.global.colors['light-1'] : theme.global.colors['light-3'],
    color: selected ? theme.global.colors.brand : theme.global.colors['dark-3'],
    borderColor: selected ? theme.global.colors.brand : theme.global.colors['dark-3'],
  },
  '&:hover': {
    background: theme.global.colors['light-1'],
    color: theme.global.colors.brand,
    borderColor: theme.global.colors.brand,
    '&:not(:disabled) + button': {
      borderLeftColor: theme.global.colors.brand,
    },
    '&:disabled': {
      background: selected ? theme.global.colors['light-1'] : theme.global.colors['light-3'],
      color: selected ? theme.global.colors.brand : theme.global.colors['dark-3'],
      borderColor: selected ? theme.global.colors.brand : theme.global.colors['dark-3'],
      cursor: 'inherit',
    },
  },
  '&:first-of-type': {
    borderRadius: '3px 0 0 3px',
  },
  '&:last-child': {
    borderRadius: '0 3px 3px 0',
  },
  img: {
    marginRight: 10,
  },
}))
