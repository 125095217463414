import axios, { AxiosInstance, AxiosRequestConfig } from "axios"
import { API_URL, AUTH_API_URL, LEAD_API_URL, OLD_API_URL, WHITE_LABEL_API_URL } from "config"
import { useNotificationStore } from "libs/notifications";
import { getAccessToken } from "./auth0";

axios.defaults.timeout = 0

const authRequestInterceptor = async (
  config: AxiosRequestConfig,
): Promise<AxiosRequestConfig> => {
  const token = await getAccessToken()

  if (token) {
    config.headers.authorization = `Bearer ${token}`
  }

  return config
}

type CreateAxiosInstanceParams = {
  baseURL: string
  isPublic?: boolean
}

export const createAxiosInstance = (params: CreateAxiosInstanceParams): AxiosInstance => {
  const { baseURL, isPublic } = params

  const instance = axios.create({ baseURL })

  if (!isPublic) {
    instance.interceptors.request.use(authRequestInterceptor)
  }

  instance.interceptors.response.use(
    response => {
      return response.data
    },
    async error => {
      const { data } = error.response

      if (data.error && data.error.resource !== 'theme') {
        if (data.error.last) {
          useNotificationStore.getState().addNotification({
            type: 'error',
            code: data.error.last.code || data.error.message,
          })
        } else {
          useNotificationStore.getState().addNotification({
            type: 'error',
            message: data.error.message,
          })
        }
      }

      return Promise.reject(data)
    },
  )

  return instance
}

export const oldApiVisiretail = createAxiosInstance({ baseURL: OLD_API_URL })

export const apiVisiretail = createAxiosInstance({ baseURL: API_URL })

export const apiAuth = createAxiosInstance({ baseURL: AUTH_API_URL })

export const apiAuthPublic = createAxiosInstance({ baseURL: AUTH_API_URL, isPublic: true })

export const apiWhiteLabel = createAxiosInstance({ baseURL: WHITE_LABEL_API_URL, isPublic: true })

export const apiLead = createAxiosInstance({ baseURL: LEAD_API_URL })
