import { GlobalStatistics } from "./GlobalStatistics";
import { GlobalProductStatistics } from "./GlobalProductStatistics";
import { DetailedStatistics } from "./DetailedStatistics";
import { DailyClickChartStatistics } from "./DailyClickChartStatistics";
import { PointOfSalesListStatistics } from "./PointOfSalesListStatistics";
import { PointOfSaleSearchStatistics } from "./PointOfSaleSearchStatistics";
import { CampaignsListStatistics } from "./CampaignsListStatistics";
import { DateNavBar } from "./DateNavBar";
import Dashboard from "./Dashboard";

export {
  GlobalStatistics,
  GlobalProductStatistics,
  DetailedStatistics,
  DailyClickChartStatistics,
  PointOfSalesListStatistics,
  PointOfSaleSearchStatistics,
  CampaignsListStatistics,
  DateNavBar,
}

export default Dashboard
