import { ThemeContext } from 'grommet'
import { useContext } from 'react'
import { FC } from 'react'

type Props = {
  size?: number
  color?: string
}

export const withIcon = (WrappedComponent: FC<any>) => ({ color, size = 16 }: Props) => {
  const {
    global: { colors },
  } = useContext<any>(ThemeContext)

  const fill = color ? colors[color] || color : colors['dark-500']

  return (
    <svg viewBox="0 0 14 14" width={size} height={size} fill={fill}>
      <WrappedComponent />
    </svg>
  )
}
