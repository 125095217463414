import React, { Component } from 'react'
import { Box, Heading, Image } from 'grommet'

export default class EmptyData extends Component {
  render() {
    return (
      <Box
        background={this.props.backgroundColor && this.props.backgroundColor}
        align="center"
        pad="large"
        height="40vh"
      >
        <Image src={require('../../../assets/images/empty_2.png').default} alt="panier vide" fit="contain" />
        {this.props.title && (
          <Heading level="1" size="small">
            {this.props.title}
          </Heading>
        )}
        {this.props.description && (
          <Heading
            level="5"
            fill
            style={{
              backgroundColor: this.props.backgroundColor && this.props.backgroundColor,
            }}
          >
            {this.props.description}
          </Heading>
        )}
      </Box>
    )
  }
}
