import React from 'react'
import { Box, CheckBox, Select, Text } from 'grommet'
import { indexOf, some } from 'lodash/fp'

const Option = ({ render, selected, ...props }) => (
  <Box direction="row" align="center" flex={false}>
    <CheckBox checked={selected} label={<Text>{render}</Text>} />
  </Box>
)

export const MultiSelect = ({ select, options, onSearch, onChange, ...props }) => {
  const handleChange = ({ option }) => {
    const newSelect = [...(select || [])]
    const selectedIndex = indexOf(option)(newSelect)
    if (selectedIndex >= 0) {
      newSelect.splice(selectedIndex, 1)
    } else {
      newSelect.push(option)
    }
    onChange(newSelect)
  }

  return (
    <Select
      closeOnChange={false}
      multiple
      options={[{ value: '-1', render: 'Tout sélectionner' }, ...options]}
      value={select}
      size="small"
      onChange={handleChange}
      {...props}
    >
      {option => <Option {...option} selected={indexOf(option)(select) >= 0} />}
    </Select>
  )
}
