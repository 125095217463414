import {
  RESET_PASSWORD_REJECTED,
  RESET,
  ASK_RESET_PASSWORD_REJECTED,
  ASK_RESET_PASSWORD_FULLFILLED,
  RESET_PASSWORD_FULLFILLED,
} from '../actions/types'

const INITIAL_STATE = {
  askResetFailed: false,
  resetFailed: false,
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ASK_RESET_PASSWORD_FULLFILLED:
      return { ...state, askResetFailed: false }
    case ASK_RESET_PASSWORD_REJECTED:
      return { ...state, askResetFailed: true }
    case RESET_PASSWORD_FULLFILLED:
      return { ...state, resetFailed: false }
    case RESET_PASSWORD_REJECTED:
      return { ...state, resetFailed: true }
    case RESET:
      return INITIAL_STATE
    default:
      return state
  }
}
