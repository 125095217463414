import { Box } from 'grommet'
import React, { FC } from 'react'
// @ts-ignore
import OutsideClickHandler from 'react-outside-click-handler'

type Props = {
  open?: boolean
  onClose: () => void
}

export const Sidebar: FC<Props> = ({ children, open = false, onClose }) => {
  if (!open) {
    return null
  }

  return (
    <Box fill style={{ position: 'fixed', top: 0, left: 0, zIndex: 200, background: 'rgba(0,0,0,.4)' }}>
      <OutsideClickHandler onOutsideClick={onClose}>
        <Box 
          background="white" 
          elevation="medium" 
          pad={{ vertical: 'xlarge', horizontal: 'large' }}
          animation={{ type: 'slideRight', size: 'large' }} 
          style={{ position: 'fixed', top: 0, bottom: 0, width: '300px' }}
          fill="horizontal"
          gap="large"
        >
          <Box pad={{ vertical: 'medium' }} overflow="auto" fill>
            {children}
          </Box>
        </Box>
      </OutsideClickHandler>
    </Box>
  )
}