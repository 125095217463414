import React, { useEffect, useState, useContext } from 'react'
import { Box, Heading, Pagination, ResponsiveContext, Spinner, Text } from 'grommet'
import { filter, includes, map } from 'lodash/fp'

import { Header } from './Header'
import { SearchBar } from './SearchBar'
import { Body } from './Body'

import { ResponsiveGrid } from '../ResponsiveGrid'
import { isLoading } from 'old/helpers/statusAction'

const DEFAULT_STEP = 10

const GRID_LAYOUT = {
  small: {
    gap: 'small',
    areas: [['search'], ['header'], ['body']],
  },
  default: {
    gap: 'small',
    areas: [['header'], ['search'], ['body'], ['pagination']],
  },
}

export const DataTable = ({
  columns,
  data,
  totalCount,
  searcheable,
  select,
  sort,
  onSelect,
  onSelectAll,
  onSort,
  onSearch,
  onPaginationChange,
  rowDetails,
  primaryKey,
  paginate,
  style,
  layout,
  loading
}) => {
  const [selectedData, setSelectedData] = useState(select || [])
  const [rowExpanded, setRowExpanded] = useState([])

  const size = useContext(ResponsiveContext)

  useEffect(() => {
    if (onSelect) {
      onSelect(selectedData)
    }
  }, [selectedData])

  const handleCheckAll = checked => {
    setSelectedData((a) => checked ? [...a, ...map(primaryKey)(data)] : selectedData.filter(s => !data.map(d => d[primaryKey]).includes(s)))
  }

  const handleCheck = (checked, primary) => {
    setSelectedData(
      checked ? [...selectedData, primary] : filter(establishment => establishment !== primary)(selectedData),
    )
  }

  const handleSort = property => {
    onSort({
      property,
      direction: sort.property !== property ? 'desc' : sort.direction === 'desc' ? 'asc' : 'desc',
    })
  }

  const handleExpand = rowIndex => {
    setRowExpanded(
      includes(rowIndex)(rowExpanded) ? filter(index => index !== rowIndex)(rowExpanded) : [...rowExpanded, rowIndex],
    )
  }

  const isSelectable = onSelect !== undefined
  const newColumns = columns[size] || columns.default || columns

  return (
    <ResponsiveGrid layout={layout || GRID_LAYOUT}>
      <Header
        columns={newColumns}
        isSelectable={isSelectable}
        totalSelect={selectedData.length}
        totalData={data.length}
        totalCount={totalCount}
        data={data}
        select={selectedData}
        primaryKey={primaryKey}
        sort={sort}
        style={style?.header}
        onCheckAll={handleCheckAll}
        onSelectAll={onSelectAll}
        onSort={handleSort}
      />
      {searcheable && <SearchBar style={style?.searchBar} onChange={onSearch} {...searcheable} />}
      {!loading ? (
        <Body
          columns={newColumns}
          data={data}
          select={selectedData}
          rowExpanded={rowExpanded}
          isSelectable={isSelectable}
          primaryKey={primaryKey}
          style={style?.body}
          onCheck={handleCheck}
          onExpand={handleExpand}
          rowDetails={rowDetails}
        />
      ) : (
        <Box direction="row" gap="small" pad="small" align="center" justify="center" height="100%">
          <Spinner />
          <Text>Chargement des établissements...</Text>
        </Box>
      )}
      {paginate && data.length > (paginate.step || DEFAULT_STEP) && (
        <Pagination
          gridArea="pagination"
          align="center"
          fill="horizontal"
          margin={{ top: 'xlarge' }}
          numberItems={data.length}
          onChange={onPaginationChange}
          {...paginate}
          {...style?.pagination}
        />
      )}
    </ResponsiveGrid>
  )
}
