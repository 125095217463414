import React from 'react'
import { Box, Heading, Text, Meter } from 'grommet'
import moment from 'moment'
import { daysBetweenTwoDates } from './functions'

const getProgress = (beginDate, endDate) => {
  const beginDateMoment = moment(beginDate)
  const endDateMoment = moment(endDate)
  const nowMoment = moment()
  const extend = daysBetweenTwoDates(beginDateMoment, endDateMoment)
  const accomplished = daysBetweenTwoDates(beginDateMoment, nowMoment)
  if (accomplished >= extend) return 100
  else if (extend > 0) {
    return (accomplished * 100) / extend
  }
}

const CampaignAdvance = props => {
  const progress = getProgress(props.start, props.end)
  const color = props.end ? 'status-ok' : '#E31837'

  return (
    <Box direction="row" gap="small" align="center">
      {props.title && <Heading level="6">{props.title}</Heading>}
      <Meter value={props.end ? progress : 100} color={color} width="100px" height="5px" round />
      <Text color={color}>{props.end ? `${Math.round(progress)}%` : 'fil-rouge'} </Text>
    </Box>
  )
}

export default CampaignAdvance
