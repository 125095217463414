import React, { Component } from 'react'
import NumberAbbreviate from 'number-abbreviate'
import { FormattedNumber } from 'react-intl'

export default class Number extends Component {
  constructor(props) {
    super(props, null, {})
    this.state = {
      value: props.value,
    }
  }

  render() {
    let result
    if (this.props.abbreviation) {
      result = this.getAbbreviatedNumber()
    } else {
      result = this.renderNumber(this.props.value)
    }
    return result
  }

  getAbbreviatedNumber() {
    let value = new NumberAbbreviate(['k', 'M', 'G', 'T'])
    value = value.abbreviate(this.props.value, 2)
    const abbreviation = value.toString().slice(-1)
    if (!isNaN(abbreviation)) {
      return this.renderNumber(value)
    } else {
      value = value.toString().slice(0, -1)
      return this.renderNumber(value, abbreviation)
    }
  }

  renderNumber(value, abbreviation) {
    return (
      <span className={this.props.size && `${this.props.size}-number`}>
        {!this.props.notAvailable ? (
          <>
            <FormattedNumber
              value={this.formatValueConsideringStyle(value)}
              maximumSignificantDigits={this.props.maximumSignificantDigits}
            />
            {abbreviation}
            {this.renderUnit()}
          </>
        ) : (
          'N/A'
        )}
      </span>
    )
  }

  renderUnit() {
    switch (this.props.style) {
      case 'currency':
        switch (this.props.currency) {
          case 'eur':
            return '€'
          default:
            break
        }
        break
      case 'percent':
        return '%'
      case 'day':
        let r = ''
        this.props.value <= 1 ? (r = ' jour') : (r = ' jours')
        return r
      default:
        break
    }
  }

  formatValueConsideringStyle(value) {
    switch (this.props.style) {
      case 'percent':
        return (value *= 100)
      case 'day':
        return Math.round(value)
      default:
        return value
    }
  }
}
