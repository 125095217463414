import { Duration, Periodicity } from 'libs/date'

export const PERIOD: Record<keyof Duration, string> = {
  years: 'an',
  months: 'mois',
  weeks: 'semaine',
  days: 'jour',
}

export const PERIODICITY: Record<Periodicity, string> = {
  monthly: '/mois',
  yearly: '/an',
}

export * from './errors'
