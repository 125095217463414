import React, { useState, useEffect } from 'react'
import { Box } from 'grommet'
import { Markdown } from 'shared'
import CGSMarkdownFile from 'assets/cgs.md'

const url = typeof window !== undefined ? window.location.href : ''
export const showCGS = url.includes('visiretail') || url.includes('localhost')

const CGSPage = () => {
  const [text, setText] = useState('')

  useEffect(() => {
    fetch(CGSMarkdownFile)
      .then(res => res.text())
      .then(md => setText(md))
      .catch(err => console.error(err))
  }, [])

  return <Box pad="large">{showCGS && <Markdown content={text} />}</Box>
}

export default CGSPage
