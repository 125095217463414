import React, { useContext } from 'react'
import { Box, ResponsiveContext } from 'grommet'

export const ChoicePannelContent = ({ children }) => {
  const size = useContext(ResponsiveContext)

  return size !== 'small' ? (
    <Box margin={{ left: 'large' }}>
      <Box margin={{ left: 'small' }} gap="medium">
        {children}
      </Box>
    </Box>
  ) : (
    <Box margin={{ left: 'xlarge' }} gap="medium">
      {children}
    </Box>
  )
}
