type Params = {
  [key: string]: string | number | boolean
}

export const parseQueryParams = (params?: Params): string => {
  return params
    ? Object.keys(params)
        .filter(key => params[key])
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&')
    : ''
}
