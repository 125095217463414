import { FC, Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

// @ts-ignore
import { addLocaleData, IntlProvider } from 'react-intl'
// @ts-ignore
import frLocaleData from 'react-intl/locale-data/fr'
import { QueryClientProvider } from 'react-query'
import { initializeApp } from 'firebase/app'
//import { ReactQueryDevtools } from 'react-query/devtools'
import { queryClient } from 'libs/react-query'
import { store } from 'libs/redux'
import { firebaseConfig } from 'config'

addLocaleData(frLocaleData)
initializeApp(firebaseConfig)

export const AppProvider: FC = ({ children }) => {
  return (
    <Suspense fallback="">
      <Provider store={store}>
        <RecoilRoot>
          <QueryClientProvider client={queryClient}>
            {/*process.env.NODE_ENV !== 'production' && <ReactQueryDevtools />*/}
            <IntlProvider locale="fr">
              <BrowserRouter>
                {children}
              </BrowserRouter>
            </IntlProvider>
          </QueryClientProvider>
        </RecoilRoot>
      </Provider>
    </Suspense>
  )
}
