import { Box, Image, Text } from 'grommet'
import { NotificationType, Notification as NotificationProps } from './types'
import styled from 'styled-components'

import InfoIcon from './icons/info.svg'
import SuccessIcon from './icons/success.svg'
import ErrorIcon from './icons/error.svg'
import CloseIcon from './icons/close.svg'
import { useEffect } from 'react'

const icons: Record<NotificationType, { color: string; icon: string }> = {
  info: {
    color: 'status-info',
    icon: InfoIcon,
  },
  warning: {
    color: 'status-warning',
    icon: ErrorIcon,
  },
  success: {
    color: 'status-ok',
    icon: SuccessIcon,
  },
  error: {
    color: 'status-error',
    icon: ErrorIcon,
  },
}

const RoundButton = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#EDF2F7',
  borderRadius: '50%',
  width: '32px',
  height: '32px',
  fontWeight: 'bold',
  cursor: 'pointer',
})

type Props = {
  notification: NotificationProps
  onDismiss: (id: string) => void
}

export const Notification = ({ notification, onDismiss }: Props) => {
  const { id, type, message } = notification

  const { color, icon } = icons[type]

  useEffect(() => {
    setTimeout(() => onDismiss(id), 5000)
  }, [])

  return (
    <Box
      direction="row"
      background="white"
      gap="medium"
      overflow="hidden"
      animation={['fadeIn', 'slideLeft']}
      elevation="small"
      round
    >
      <Box background={color} justify="center" align="center" width="32px">
        <Image src={icon} />
      </Box>
      <Box direction="row" align="center" pad={{ vertical: 'medium', horizontal: 'small' }} gap="medium">
        <Text>{message}</Text>
        <RoundButton onClick={() => onDismiss(id)}>
          <Image src={CloseIcon} />
        </RoundButton>
      </Box>
    </Box>
  )
}
