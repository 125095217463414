import moment from 'moment/moment'

export const displayNameOfUser = user => {
  let result = ''
  if (!user) return result
  if (user.name || user.surname) {
    if (user.name) result += capitalize(user.name)
    if (user.surname) result += ' ' + capitalize(user.surname)
  } else {
    result = user.username
  }
  return result
}

export const capitalize = (string, start = 0) => {
  if (string) {
    return string.charAt(start).toUpperCase() + string.slice(start + 1)
  }
}

export const toUpperCase = string => {
  if (string) return string.stringify().toUpperCase()
}

export const daysBetweenTwoDates = (startDate, endDate) => {
  return moment(endDate).diff(moment(startDate), 'days')
}

export const getLastPartOfString = (needle, string) => {
  return string.substr(string.lastIndexOf(needle) + 1)
}
