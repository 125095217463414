import React, { createElement } from 'react'
import { Box, Grid, Text } from 'grommet'
import { isString, reduce } from 'lodash'
import styled from 'styled-components'
import TabSvg from '../assets/images/tab.svg'
import TabFirstSvg from '../assets/images/tab-first.svg'
import TabLastSvg from '../assets/images/tab-last.svg'

const sizeSideSpace = '1.25rem'

const MaskBox = styled(Box)(({ active, tabValue, tabMaxValue, shapeSideRound }) => ({
  maskImage: `url(${
    shapeSideRound ? TabSvg : tabValue === 0 ? TabFirstSvg : tabValue === tabMaxValue ? TabLastSvg : TabSvg
  })`,
  maskSize: '100% 100%',
  maskPosition: 'bottom',
  maskRepeat: 'no-repeat',
  zIndex: active ? 1 : 0,
}))

export const Tabs = ({
  tabs,
  backgroundColor,
  backgroundColorActive,
  textColor,
  textColorActive,
  activeValue,
  shapeSideRound,
  onClick,
  ...props
}) => {
  const columns = reduce(
    tabs,
    res => {
      res.push('1fr', sizeSideSpace)
      return res
    },
    [sizeSideSpace],
  )
  return (
    <Box {...props}>
      <Grid columns={columns} rows={['3.75rem']}>
        {tabs.map(tab => (
          <Box
            key={tab.value}
            justify="center"
            gridArea={`1 / ${tab.value * 2 + 1} / 2 / ${tab.value * 2 + 4}`}
            onClick={() => onClick(tab.value)}
          >
            <MaskBox
              fill
              background={{
                position: 'bottom',
                repeat: 'no-repeat',
                size: '100% 100%',
                color: activeValue === tab.value ? backgroundColorActive : backgroundColor,
              }}
              align="center"
              justify="center"
              active={activeValue === tab.value}
              tabValue={tab.value}
              tabMaxValue={tabs.length - 1}
              shapeSideRound={shapeSideRound}
            >
              {isString(tab.label) ? (
                <Text
                  textAlign="center"
                  margin="medium"
                  size="small"
                  color={activeValue === tab.value ? textColorActive : textColor}
                >
                  {tab.label}
                </Text>
              ) : (
                createElement(tab.label, { color: activeValue === tab.value ? textColorActive : textColor })
              )}
            </MaskBox>
          </Box>
        ))}
      </Grid>
    </Box>
  )
}

Tabs.defaultProps = {
  tabs: [{ label: 'label', value: '0' }],
  backgroundColor: 'default',
  backgroundColorActive: 'light-1',
  textColor: 'dark-2',
  textColorActive: 'brand',
  activeValue: 0,
  round: false,
  onClick: () => {},
}
