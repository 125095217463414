import React from 'react'
import { Box, Grid, Text } from 'grommet'
import { useSelector } from 'react-redux'
import Number from '../../../components/Number'
import Loading from '../../../components/loading'
import Card from '../../../components/card'
import { selectLoadingStatistics } from '../storeChunk'
import { globalStatisticsSelector } from '../selectors'

export const GlobalStatistics = () => {
  const loading = useSelector(selectLoadingStatistics)
  const { companiesStatus, clicks, conversions, budget } = useSelector(globalStatisticsSelector)

  return (
    <Grid columns="medium" gap="large" className="mesdata_list">
      <Box>
        <Card label="établissements actifs">
          {!loading ? (
            <Text size="xxlarge">
              {companiesStatus.active}/{companiesStatus.total}
            </Text>
          ) : (
            <Loading />
          )}
        </Card>
      </Box>
      <Box>
        <Card label="clic">
          {!loading ? <Number value={clicks} abbreviation={true} maximumSignificantDigits={3} /> : <Loading />}
        </Card>
      </Box>
      {conversions > 0 && (
        <Box>
          <Card label="conversions">
            {!loading ? <Number value={conversions} abbreviation={true} maximumSignificantDigits={3} /> : <Loading />}
          </Card>
        </Box>
      )}
      <Box>
        <Card label="total dépensé" accent>
          {!loading ? (
            <Number value={budget} abbreviation={true} style="currency" currency="eur" maximumSignificantDigits={3} />
          ) : (
            <Loading color={'white'} />
          )}
        </Card>
      </Box>
    </Grid>
  )
}
