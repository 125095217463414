import { ErrorCode } from 'libs/notifications/types'

export const DEFAULT_ERROR_MESSAGE = 'Service momentanément indisponible'

export const ERRORS_MESSAGE: Record<ErrorCode, string> = {
  ErrCatalogNotFound: 'Catalogue introuvable',
  ErrUserAuthorizationDenied: "Vous n'avez pas la permission",
  ErrTagSlugInvalid: 'Recherche par mot clé invalide',
  ErrBadCursor: '',
  ErrNegotiatedNotFound: 'Produit introuvable',
  unknown: DEFAULT_ERROR_MESSAGE,
  invalid: DEFAULT_ERROR_MESSAGE
}
