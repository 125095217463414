import React, { Component } from 'react'
import HighchartsReact from 'highcharts-react-official'
//import campaigns from "./plugins/campaigns";
import Highcharts from 'highcharts/highstock'
import splineEvents from './plugins/spline-events'
import wraps from './plugins/wraps'

class Chart extends Component {
  constructor(props) {
    super(props)
    //campaigns(Highcharts);
    splineEvents(Highcharts, { lineColor: props.lineColor })
    wraps(Highcharts)
  }

  render() {
    return <HighchartsReact highcharts={Highcharts} constructorType={'chart'} options={this.props.options} />
  }
}

export default Chart
