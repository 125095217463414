import { API_URL } from 'config'
import { createAxiosInstance } from 'libs/axios'
import { Cursor } from '.'

export type VisicoreV2Response<T> = {
  data: T
  cursor?: Cursor
}

export const visicoreV2InstanceApi = createAxiosInstance({ baseURL: `${API_URL}/v2` })
