import React, { useContext } from 'react'
import { Box, ThemeContext } from 'grommet'
import { useSelector } from 'react-redux'
import { isEmpty, max, map } from 'lodash/fp'
import { selectLoadingDailyClick, selectStatisticsDates } from '../storeChunk'
import { normalizedDailyClickSelector, normalizedDailyClickForUserSelector } from '../selectors'
import Chart from '../../../components/charts/Chart'
import Loading from '../../../components/loading'
import EmptyData from '../../../templates/v1/emtyData/EmptyData'

export const DailyClickChartStatistics = ({ forUser }) => {
  const theme = useContext(ThemeContext)
  const loading = useSelector(selectLoadingDailyClick)
  const { since, until } = useSelector(selectStatisticsDates)
  const normalizedDays = useSelector(
    forUser === 'me' ? normalizedDailyClickSelector : normalizedDailyClickForUserSelector(forUser),
  )

  const yMax = max(map(day => day.y)(normalizedDays))
  const yMin = 0

  let interval = 10
  if (yMax >= 100) {
    interval = Math.ceil((yMax - yMin) / 100 / 10) * 100
  }
  if (yMax >= 1000) {
    interval = Math.ceil((yMax - yMin) / 500 / 10) * 500
  }

  const chartOptions = {
    credits: {
      enabled: false,
    },
    chart: {
      renderTo: 'container',
      marginLeft: 0,
      height: '350px',
      maskMargin: 150,
      maskColor: 'rgba(255,255,255,0.6)',
    },
    title: {
      text: ' ',
    },
    tooltip: {
      formatter: function(tooltip) {
        if (this.series.options.type === 'splineevents') {
          return tooltip.defaultFormatter.call(this, tooltip)
        } else return false
      },
      useHTML: true,
      backgroundColor: theme.global.colors['dark-1'],
      style: {
        color: theme.global.colors['light-1'],
      },
    },
    yAxis: [
      {
        offset: -150,
        tickInterval: interval,
        min: yMin,
        max: yMax,
        labels: {
          y: -10,
        },
        title: {
          text: '',
        },
      },
      {
        min: 0,
        max: 5,
        gridLineWidth: 0,
        height: '30%',
        top: '55%',
      },
    ],
    plotOptions: {
      series: {
        states: {
          hover: {
            halo: false,
          },
        },
        point: {
          events: {
            mouseOver: function() {
              if (this.customPath) {
                this.customPath.addClass('mouseover')
              }
            },
            mouseOut: function() {
              if (this.customPath) {
                this.customPath.removeClass('mouseover')
              }
            },
          },
        },
      },
    },
    xAxis: {
      type: 'datetime',
      min: Date.parse(since),
      max: Date.parse(until),
      crosshair: {
        enabled: true,
        color: theme.global.colors.brand,
        width: 2,
        zIndex: 4,
      },
    },
    series: [
      {
        type: 'areaspline',
        showInLegend: false,
        data: normalizedDays,
        marker: {
          enabled: false,
        },
        lineWidth: 0,
        fillColor: {
          linearGradient: [0, 0, 0, 250],
          stops: [
            [0, theme.global.colors.brand],
            [1, 'rgba(255,255,255,0.1)'],
          ],
        },
        enableMouseTracking: false,
      },
      {
        type: 'splineevents',
        dashedCross: true,
        tooltip: {
          headerFormat: '<table style = "color:white; "><tr><td style="text-align: center">{point.key}</td></tr>',
          pointFormat: '<tr><td style="text-align: center"><b>{point.y} CLICS</b></td></tr>',
          footerFormat: '</table>',
        },
        pointStart: Date.parse(since),
        pointInterval: 1000 * 3600 * 40,
        data: normalizedDays,
        zoneAxis: 'x',
        marker: {
          enabled: false,
          symbol: 'circle',
          fillColor: theme.global.colors['dark-1'],
        },
      },
    ],
  }

  return !loading ? (
    !isEmpty(normalizedDays) ? (
      <Box background="green">
        <Chart options={chartOptions} lineColor={theme.global.colors.brand} />
      </Box>
    ) : (
      <EmptyData
        backgroundColor="#fff"
        description="Vous n'avez pas encore de données disponible pour afficher le graphique"
      />
    )
  ) : (
    <center>
      <Loading />
    </center>
  )
}
