import React from 'react'
import { Box } from 'grommet'
import Number from '../components/Number'

const BudgetBar = props => {
  const budgetBarColors = ['#34A853', '#337ab7', '#006491']

  const getPercentage = (budget, spend) => {
    let percentage = 0
    if (budget > 0) {
      percentage = (spend * 100) / budget
    }

    return Math.min(Math.max(Math.round(percentage), 0), 100)
  }

  const getAllSpend = () => {
    return props.spendIn.map((e, i) => {
      return e === undefined || e === 0 ? (
        ''
      ) : (
        <div
          key={i}
          className="progress-bar"
          role="progressbar"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{
            width: `${getPercentage(props.budget, e)}%`,
            background: `${budgetBarColors[i]}`,
          }}
        >
          <span>{getPercentage(props.budget, e)}%</span>
        </div>
      )
    })
  }

  return (
    <Box className="progress" direction="row" background="dar-2" fill>
      {getAllSpend()}
      {props.budgetDisplay == true ? (
        <span className="budeget_data">
          <Number
            value={parseFloat(props.budget)}
            abbreviation={true}
            style="currency"
            currency="eur"
            maximumSignificantDigits={3}
          />
        </span>
      ) : (
        ''
      )}
    </Box>
  )
}

export default BudgetBar
