export const DEFAULT_METADATA = {
  title: 'Visiretail, pilotez la digitalisation de votre réseau | Visiperf',
  favicon: require('../assets/images/favicon.ico').default,
}

export const DEFAULT_ASSETS = {
  fonts: [],
  images: {
    favicon: require('../assets/images/favicon.ico').default,
    avatarUrl: require('../assets/images/profile-user.svg').default,
    emptyCartUrl: require('../assets/images/panier_vide.png').default,
    campaignsMenuUrl: require('../assets/images/mes-campagnes.svg').default,
    dashboardMenuUrl: require('../assets/images/tableau-de-bord.svg').default,
    loginPresentation: require('../assets/images/login-presentation.png').default,
    pointOfSalesMenuUrl: require('../assets/images/mes-points-de-vente.svg').default,
    logoOnBackgroundColor: require('../assets/images/visiretail-logo.svg').default,
    logoSplashScreen: require('../assets/images/visiretail-logo-splashscreen.svg').default,
  },
  opengraph: {
    url: 'visiretail.io',
    image: '',
    title: 'Visiretail by Visiperf',
    description: 'description',
    site_name: 'Visiretail',
  },
  manifest: {
    short_name: 'Visiretail',
    name: 'Visiretail by Visiperf',
    icons: [
      {
        src: require('../assets/images/favicon.ico').default,
        sizes: '64x64 32x32 24x24 16x16',
        type: 'image/x-icon',
      },
    ],
    start_url: './index.html',
    display: 'standalone',
    theme_color: '#000000',
    background_color: '#ffffff',
  },
}
