import { Container } from 'components/layouts'
import { Box, Button, Image, Menu, Text } from 'grommet'
import { Advertising, Analytics } from 'libs/icons'
import { displayNameOfUser } from 'old/components/functions'
import { useSelector } from 'react-redux'
import { Link, useMatch, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { StatusButton } from 'old/components'
import { useQuery } from 'react-query'
import { getPointOfSalesHasMinManagerRole } from 'api/visiretail/v2/point-of-sales'
import { useEffect } from 'react'

type NavLinkProps = {
  to: string
  icon: any
  label: string
}

const NavLink = ({ to, icon: Icon, label }: NavLinkProps) => {
  const isActive = useMatch(`${to}/*`)

  const background = isActive ? 'light-200' : ''
  const color = isActive ? 'brand' : 'dark-500'

  return (
    <Link to={to} style={{ height: '100%' }}>
      <Box background={background} direction="row" gap="medium" align="center" pad={{ horizontal: 'large' }} fill>
        {Icon && <Icon color={color} />}
        <Text color={color}>{label}</Text>
      </Box>
    </Link>
  )
}

const StatsListItems = () => [
  {
    label: (
      <Box fill>
        <NavLink icon="" to="/analytics/dashboard" label="Consulter mes statistiques de campagnes" />
      </Box>
    ),
  },
  {
    label: (
      <Box fill>
        <NavLink icon="" to="/analytics/network-overview" label="Vue d’ensemble du réseau" />
      </Box>
    ),
  },
  {
    label: (
      <Box fill>
        <NavLink icon="" to="/analytics/campaign-overview/now" label="Mes Campagnes" />
      </Box>
    ),
  },
]

export const MainNavigation = () => {
  const { user, logout } = useAuth0()
  const { data: pointOfSales } = useQuery(['point-of-sales', { minRole: 'manager' }], () => getPointOfSalesHasMinManagerRole(''))

  const { logoUrl } = useSelector((state: any) => state.theme)

  const isAnalyticsMatch = useMatch('/analytics/*')
  const isOnboarding = useMatch('/onboarding/*')

  const navigate = useNavigate()

  if (!user) {
    return null
  }

  return (
    <Box
      as="header"
      direction="row"
      justify="between"
      background="white"
      height="70px"
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 10,
        boxShadow: '0px 2px 11px rgba(0, 0, 0, 0.07)',
      }}
    >
      <Container>
        <Box as="nav" direction="row" align="center" fill>
          <Link to="/catalogs">
            <Box justify="start" flex="shrink" pad="large" width="10rem" height="100%">
              <Image src={logoUrl} alt="Brand logo" style={{ maxHeight: '50px' }} />
            </Box>
          </Link>
          {!isOnboarding && (
            <>
              <NavLink to="/catalogs" icon={Advertising} label="Mes catalogues de publicités" />
              <Box background={isAnalyticsMatch ? 'light-200' : ''} fill="vertical">
                <Menu
                  dropAlign={{ left: 'left', top: 'bottom' }}
                  label={
                    <Box direction="row" gap="medium" align="center" pad={{ horizontal: 'large' }} fill>
                      <Analytics color={isAnalyticsMatch ? 'brand' : 'dark-500'} />
                      <Text color={isAnalyticsMatch ? 'brand' : 'dark-500'}>Analytics</Text>
                    </Box>
                  }
                  items={StatsListItems()}
                  fill="vertical"
                />
              </Box>
            </>
          )}
        </Box>
        <Box direction="row" justify="end" align="center" gap="medium" fill="vertical" width="700px" pad={{ horizontal: "medium" }}>
          {(!isOnboarding && pointOfSales?.data.some(p => !p.paymentMethod)) && (
            <Link to="onboarding">
              <StatusButton label="Compléter le profil" status="COMPLETE" />
            </Link>
          )}
          <Box direction="row" align="center" fill="vertical">
            <Menu
              dropAlign={{ left: 'left', top: 'bottom' }}
              label={
                <Box direction="row" align="center" gap="medium">
                  <Text>{displayNameOfUser(user)}</Text>
                  <Box
                    justify="center"
                    align="center"
                    background="brand"
                    width="32px"
                    height="32px"
                    round="50%"
                  >
                    <Text color="light-200">
                      {user.name?.charAt(0).toUpperCase()}
                    </Text>
                  </Box>
                </Box>
              }
              items={[
                { label: 'Moyens de paiement', onClick: () => navigate('onboarding') },
                { label: 'Se déconnecter', onClick: () => {
                  localStorage.removeItem('visiretail:onboarding')
                  logout({ returnTo: window.location.origin })
                } },
              ]}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
