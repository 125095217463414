import { RESET, AUTH_GET_ONE_USER, AUTH_GET_ONE_USER_FULLFILLED, AUTH_GET_ONE_USER_REJECTED } from '../actions/types'

const INITIAL_STATE = {
  selectedUser: {
    id: null,
    username: null,
    email: null,
    roles: [],
    adwordsCustomersId: [],
    facebookAccountsId: [],
    group: {},
    name: null,
    surname: null,
    telephone: null,
    openPayment: true,
    basketId: null,
  },
  fetchingUser: false,
  fetchedUser: false,
  errorMessage: null,
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH_GET_ONE_USER:
      return {
        ...state,
        fetchedUser: false,
        fetchingUser: true,
      }
    case AUTH_GET_ONE_USER_FULLFILLED:
      return {
        ...state,
        selectedUser: action.payload,
        fetchedUser: true,
        fetchingUser: false,
      }
    case AUTH_GET_ONE_USER_REJECTED:
      return {
        ...state,
        errorMessage: action.payload,
        fetchedUser: true,
        fetchingUser: false,
      }
    case RESET:
      return INITIAL_STATE
    default:
      return state
  }
}
