import { getStatistics, getDailyClickStatistics } from './api'
import {
  lastCallActions,
  selectLastCall,
  selectStatisticsDates,
  statisticsActions,
  dailyClickActions,
  loadingStatisticsActions,
  loadingDailyClickActions,
  statisticsDatesActions,
} from './storeChunk'
import { canRefreshStatistics, getLastYear, getLast6Months, getLastMonth, getLastWeek, getCustomDates } from './utils'
import moment from 'moment'

export const fetchStatisticsAction = params => async dispatch => {
  dispatch(loadingStatisticsActions.replace(true))
  try {
    const { data } = await getStatistics(params)
    dispatch(statisticsActions.replace(data))
  } catch (err) {
    console.error('fetchStatisticsAction:', err)
  } finally {
    dispatch(loadingStatisticsActions.replace(false))
  }
}

export const fetchDailyClickAction = params => async dispatch => {
  dispatch(loadingDailyClickActions.replace(true))
  try {
    const { data } = await getDailyClickStatistics(params)
    dispatch(dailyClickActions.replace(data))
  } catch (err) {
    console.error('fetchDailyClickAction:', err)
  } finally {
    dispatch(loadingDailyClickActions.replace(false))
  }
}

export const fetchAllStatisticsAction = (forceRefresh = false) => async (dispatch, getState) => {
  const lastCall = selectLastCall(getState())
  const statisticsDates = selectStatisticsDates(getState())

  if (!forceRefresh && !canRefreshStatistics(lastCall)) {
    return
  }

  try {
    dispatch(lastCallActions.replace(moment().format()))
    await Promise.all([
      dispatch(fetchStatisticsAction(statisticsDates)),
      dispatch(fetchDailyClickAction(statisticsDates)),
    ])
  } catch (err) {
    console.error('fetchAllStatisticsAction:', err)
  }
}

export const selectDateRangeAction = ({ range, since, until }) => dispatch => {
  let dateRange
  switch (range) {
    case 'year':
      dateRange = getLastYear()
      break
    case 'half':
      dateRange = getLast6Months()
      break
    case 'month':
      dateRange = getLastMonth()
      break
    case 'week':
      dateRange = getLastWeek()
      break
    case 'custom':
      dateRange = getCustomDates(since, until)
      break
  }
  dispatch(statisticsDatesActions.replace({ range, ...dateRange }))
  dispatch(fetchAllStatisticsAction(true))
}
