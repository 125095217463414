import React from 'react'
import { useSelector } from 'react-redux'
import { selectLoadingStatistics } from '../storeChunk'
import { topPointOfSaleStatisticsSelector } from '../selectors'
import { PointOfSalesListTemplate } from '../../../templates/v1/pointOfSales/PointOfSalesListTemplate'

export const TopPointOfSalesListStatistics = () => {
  const loading = useSelector(selectLoadingStatistics)
  const pointOfSales = useSelector(topPointOfSaleStatisticsSelector(5))

  return <PointOfSalesListTemplate loading={loading} pointOfSales={pointOfSales} />
}
