import { LOADING, LOADING_END, RESET } from '../actions/types'

const INITIAL_STATE = {
  loading: false,
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true }
    case LOADING_END:
      return { ...state, loading: false }
    case RESET:
      return INITIAL_STATE
    default:
      return state
  }
}
