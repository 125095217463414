import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectStatisticsDates, selectLoadingStatistics } from '../storeChunk'
import { selectDateRangeAction } from '../thunk'
import DateNavBarTemplate from './DateNavBarTemplate'

export const DateNavBar = () => {
  const loading = useSelector(selectLoadingStatistics)
  const statisticsDates = useSelector(selectStatisticsDates)

  const dispatch = useDispatch()

  const handleDateRangeSelect = dateRange => {
    dispatch(selectDateRangeAction(dateRange))
  }

  return <DateNavBarTemplate loading={loading} {...statisticsDates} onDateRangeSelect={handleDateRangeSelect} />
}
