import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchAllStatisticsAction } from './thunk'

export const withStatistics = WrappedComponent => props => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAllStatisticsAction())
  }, [dispatch])

  return <WrappedComponent {...props} />
}
