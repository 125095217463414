const splineEvents = function(Highcharts, options) {
  ;(function(H) {
    var each = H.each,
      Series = H.Series,
      seriesType = H.seriesType

    /**
     * The polygon3d series type.
     *
     * @constructor seriesTypes.polygon3d
     * @augments seriesTypes.scatter3d
     */

    seriesType(
      'splineevents',
      'spline',
      {
        showInLegend: false,
        // Prototype members
      },
      {
        drawPoints: function() {
          var series = this,
            color

          Series.prototype.drawPoints.apply(this, arguments)
          each(series.points, function(p) {
            if (p.customPath) {
              p.customPath.destroy()
              p.customPath = null
            }
            if (p.plotX >= 0 && p.plotY >= 0) {
              if (p.marker && p.graphic) {
                color = p.marker.fillColor || p.series.color
                p.customPath = series.chart.renderer
                  .circle(p.plotX, p.plotY, 12)
                  .attr({
                    fill: H.color(color)
                      .setOpacity(0.1)
                      .get(),
                    stroke: color,
                    'stroke-width': 1,
                    'stroke-dasharray': 5,
                  })
                  .add(series.markerGroup)
              }
            }
          })
        },
        getZonesGraphs: function() {
          var series = this,
            eventZones = [],
            valueLow,
            valueHigh,
            xAxis = series.xAxis
          each(series.points, function(p) {
            if (p.marker) {
              valueLow = xAxis.toValue(xAxis.toPixels(p.x) - 20)
              valueHigh = xAxis.toValue(xAxis.toPixels(p.x) + 20)
              eventZones.push({
                value: valueLow,
                color: options.lineColor,
              })
              eventZones.push({
                value: valueHigh,
                color: 'rgba(0,0,0,0)',
              })
            }
          })
          eventZones.push({
            color: options.lineColor,
          })
          this.zones = eventZones
          return Series.prototype.getZonesGraphs.apply(this, arguments)
        },
      },
    )
  })(Highcharts)
}

export default splineEvents
