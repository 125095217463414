import { Box } from 'grommet'
import { createPortal } from 'react-dom'
import { Notification } from './Notification'
import { useNotificationStore } from './store'

export const Notifications = () => {
  const { notifications, dismissNotification } = useNotificationStore()

  return createPortal(
    <Box gap="medium" style={{ position: 'absolute', top: '100px', right: '64px' }}>
      {notifications.map(notification => (
        <Notification key={notification.id} notification={notification} onDismiss={dismissNotification} />
      ))}
    </Box>,
    document.getElementById('notifications') as Element,
  )
}
