const wraps = function(Highcharts) {
  ;(function(H) {
    var wrap = H.wrap

    wrap(H.Point.prototype, 'destroyElements', function(p) {
      p.apply(this, Array.prototype.slice.call(arguments, 1))
      var point = this
      if (point.customPath) {
        point.customPath.destroy()
        point.customPath = null
      }
    })

    wrap(H.Chart.prototype, 'init', function(p) {
      p.apply(this, Array.prototype.slice.call(arguments, 1))

      var chart = this,
        chartOptions = chart.options.chart

      if (chartOptions && chartOptions.maskMargin && !chart.leftMask) {
        chart.leftMask = chart.renderer
          .rect(chart.plotLeft, 0, chartOptions.maskMargin, chart.chartHeight)
          .attr({
            fill: chartOptions.maskColor || 'rgba(255,255,255,0.6)',
            zIndex: 5,
          })
          .add()

        chart.rightMask = chart.renderer
          .rect(
            chart.plotWidth + chart.plotLeft - chartOptions.maskMargin,
            0,
            chartOptions.maskMargin,
            chart.chartHeight,
          )
          .attr({
            fill: chartOptions.maskColor || 'rgba(255,255,255,0.6)',
            zIndex: 5,
          })
          .add()
      }
    })

    wrap(H.Chart.prototype, 'redraw', function(p) {
      var chart = this,
        chartOptions = chart.options.chart
      p.apply(this, Array.prototype.slice.call(arguments, 1))
      if (this.rightMask) {
        this.rightMask.attr({
          x: chart.plotWidth + chart.plotLeft - chartOptions.maskMargin,
        })
      }
    })
    H.Axis.prototype.hideCrosshair = function() {
      if (this.cross) {
        this.cross.hide()
      }
      if (this.dashedCross) {
        this.dashedCross.hide()
      }
    }

    H.Axis.prototype.drawCrosshair = function(e, point) {
      var path,
        options = this.crosshair,
        pick = H.pick,
        defined = H.defined,
        fireEvent = H.fireEvent,
        snap = pick(options.snap, true),
        pos,
        categorized,
        graphic = this.cross,
        graphic2 = this.dashedCross,
        crossGroup = this.crossGroup

      fireEvent(this, 'drawCrosshair', { e: e, point: point })

      if (!e) {
        e = this.cross && this.cross.e
      }
      if (
        !point.series.options.dashedCross ||
        // Disabled in options
        !this.crosshair ||
        // Snap
        (defined(point) || !snap) === false
      ) {
        this.hideCrosshair()
      } else {
        // Get the path
        if (!snap) {
          pos = e && (this.horiz ? e.chartX - this.pos : this.len - e.chartY + this.pos)
        } else if (defined(point)) {
          // #3834
          pos = pick(
            point.crosshairPos, // 3D axis extension
            this.isXAxis ? point.plotX : this.len - point.plotY,
          )
        }

        if (defined(pos)) {
          path =
            this.getPlotLinePath(
              // First argument, value, only used on radial
              point && (this.isXAxis ? point.x : pick(point.stackY, point.y)),
              null,
              null,
              null,
              pos, // Translated position
            ) || null // #3189
        }
        if (!defined(path)) {
          this.hideCrosshair()
          return
        }

        categorized = this.categories && !this.isRadial
        path = path.slice(0, 6)
        // Draw the cross
        if (!crossGroup) {
          this.crossGroup = crossGroup = this.chart.renderer
            .g()
            .addClass('highcharts-crosshair highcharts-crosshair-' + (categorized ? 'category ' : 'thin '))
            .attr({
              zIndex: pick(options.zIndex, 2),
            })
            .add()

          this.cross = graphic = this.chart.renderer.path().add(crossGroup)

          this.dashedCross = graphic2 = this.chart.renderer.path().add(crossGroup)

          // Presentational attributes
          graphic
            .attr({
              stroke:
                options.color ||
                (categorized
                  ? H.Color('#ccd6eb')
                      .setOpacity(0.25)
                      .get()
                  : '#cccccc'),
              'stroke-width': pick(options.width, 1),
            })
            .css({
              'pointer-events': 'none',
            })

          // Presentational attributes
          graphic2
            .attr({
              stroke:
                options.color ||
                (categorized
                  ? H.Color('#ccd6eb')
                      .setOpacity(0.25)
                      .get()
                  : '#cccccc'),
              'stroke-width': pick(options.width, 1),
              dashstyle: 'dash',
            })
            .css({
              'pointer-events': 'none',
            })

          var removeEvent = H.addEvent(this, 'destroy', function() {
            if (this.crossGroup) {
              this.crossGroup = this.crossGroup.destroy()
            }
            removeEvent()
          })

          if (options.dashStyle) {
            graphic.attr({
              dashstyle: options.dashStyle,
            })
          }
        }
        path[2] = point.plotY + this.chart.plotTop
        graphic.show().attr({
          d: path,
        })
        path[2] = this.chart.yAxis[0].toPixels(0)
        path[5] = this.chart.chartHeight
        graphic2.show().attr({
          d: path,
        })
        if (categorized && !options.width) {
          graphic.attr({
            'stroke-width': this.transA,
          })
          graphic2.attr({
            'stroke-width': this.transA,
          })
        }
        this.cross.e = e
      }

      fireEvent(this, 'afterDrawCrosshair', { e: e, point: point })
    }
  })(Highcharts)
}

export default wraps
