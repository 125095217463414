import { reducers, selectLoadingStatistics } from './storeChunk'
import { fetchStatisticsAction, fetchDailyClickAction, fetchAllStatisticsAction } from './thunk'
import { globalStatisticsForUserSelector, productInfoSelector } from './selectors'
import Dashboard, {
  GlobalStatistics,
  GlobalProductStatistics,
  DetailedStatistics,
  DailyClickChartStatistics,
  PointOfSalesListStatistics,
  PointOfSaleSearchStatistics,
  CampaignsListStatistics,
  DateNavBar,
} from './components'
import { withStatistics } from './withStatistics'

export default {
  reducers,
  actions: {
    fetchStatisticsAction,
    fetchDailyClickAction,
    fetchAllStatisticsAction,
  },
  selectors: {
    globalStatisticsForUserSelector,
    productInfoSelector,
    selectLoadingStatistics,
  },
  components: {
    GlobalStatistics,
    GlobalProductStatistics,
    DetailedStatistics,
    DailyClickChartStatistics,
    PointOfSalesListStatistics,
    PointOfSaleSearchStatistics,
    CampaignsListStatistics,
    DateNavBar,
    Dashboard,
  },
  withStatistics,
}
