import React, { Component } from 'react'
import { Box, Card as GrommetCard, Heading, Image } from 'grommet'
export default class Card extends Component {
  constructor(props) {
    super(props, null, {})

    this.state = {
      label: props.label,
      img: props.img,
      alt: props.alt,
    }
  }

  getImg() {
    if (this.state.img) {
      return <Image src={this.state.img} alt={this.state.alt} alignSelf="start" width="30px" />
    }
  }

  render() {
    return (
      <GrommetCard
        background={this.props.accent ? 'brand' : 'light-1'}
        round="5px"
        elevation="xsmall"
        pad="large"
        gap="medium"
        direction="row"
        justify="between"
      >
        <Box gap="medium">
          <Heading level="2" color={this.props.accent ? 'light-1' : 'dark-1'}>
            {this.props.children}
          </Heading>
          <Heading level="5" color={this.props.accent ? 'light-1' : 'dark-3'}>
            {this.state.label}
          </Heading>
        </Box>
        {this.getImg()}
      </GrommetCard>
    )
  }
}
