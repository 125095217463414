import { Container } from 'components/layouts'
import { Box } from 'grommet'
import { FC } from 'react'

type Props = {
  aside: any
}

export const MarketLayout: FC<Props> = ({ aside, children }) => {
  return (
    <Container>
      <Box direction="row" gap="xlarge" pad={{ vertical: 'xlarge', right: 'medium' }} height={{ min: '100vh' }} fill>
        <Box width="350px" height="min-content" style={{ position: 'sticky', top: '110px' }}>
          {aside}
        </Box>
        {children}
      </Box>
    </Container>
  )
}
