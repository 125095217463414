import dayjs from 'dayjs'
import dayjsDuration from 'dayjs/plugin/duration'
import dayjsUTC from 'dayjs/plugin/utc'
import dayjsTimezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'

import 'dayjs/locale/fr'

dayjs.extend(dayjsDuration)
dayjs.extend(relativeTime)
dayjs.extend(dayjsUTC)
dayjs.extend(dayjsTimezone)
dayjs.locale('fr')
