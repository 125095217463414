import React from 'react'
import { Box } from 'grommet'
import { isEmpty } from 'lodash/fp'
import CampaignItem from './CampaignItem'
import EmptyData from '../emtyData/EmptyData'
import Loading from '../../../components/loading'

const CampaignOverviewListTemplate = ({ campaigns, loading }) =>
  !loading ? (
    !isEmpty(campaigns) ? (
      <Box gap="medium">
        {campaigns?.map(campaign => (
          <CampaignItem key={campaign.id} {...campaign} />
        ))}
      </Box>
    ) : (
      <EmptyData description={`Vous n'avez pas encore de campagnes dans cette periode`} />
    )
  ) : (
    <Loading />
  )

export default CampaignOverviewListTemplate
