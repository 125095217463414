import { Box, Layer } from 'grommet'
import React, { FC } from 'react'

type Props = {
  open?: boolean
  width?: string
  onClose: () => void
}

export const Modal: FC<Props> = ({ children, open = false, width = '700px', onClose }) => {
  if (!open) {
    return null
  }

  return open && (
    <Layer
      position="center"
      onClickOutside={onClose}
      onEsc={onClose}
    >
      <Box pad="medium" gap="small" width={width}>
        {children}
      </Box>
    </Layer>
  )
}