import { MainLayout } from 'components/layouts'
import { lazy, Suspense } from 'react'
import { Navigate, Outlet, RouteObject } from 'react-router-dom'

const OnboardingPage = lazy(() => import('pages/onboarding'))
const CatalogsPage = lazy(() => import('pages/[...catalogs]'))
const CatalogPage = lazy(() => import('pages/catalogs/[id]'))
const ProductPage = lazy(() => import('pages/products/[id]'))
const OrderPage = lazy(() => import('pages/order'))
const OrderDetailsPage = lazy(() => import('pages/orders/[id]'))

const DashboardPage = lazy(() => import('old/modules/statistics/components/Dashboard'))
const MobileStatistics = lazy(() => import('old/modules/statistics/components/MobileStatistics'))
const LeadPage = lazy(() => import('old/modules/product/containers/ProductLead'))

const CampaignOverview = lazy(() => import('old/container/campaigns/CampaignOverview'))
const ProductCampaign = lazy(() => import('old/container/campaigns/ProductCampaign'))
const NetworkOverview = lazy(() => import('old/container/pointOfSales/NetworkOverview'))
const PointOfSaleDetails = lazy(() => import('old/container/pointOfSales/PointOfSaleDetails'))

const App = () => {
  return (
    <MainLayout>
      <Suspense fallback="">
        <Outlet />
      </Suspense>
    </MainLayout>
  )
}

export const protectedRoutes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/', element: <Navigate to="/catalogs" /> },
      { path: 'catalogs', element: <CatalogsPage />, children: [{ path: ':catalogId', element: <CatalogPage /> }] },
      { path: 'products/:productId', element: <ProductPage /> },
      { path: 'products/:productId/lead', element: <LeadPage /> },
      { path: '/analytics', children: [
        { path: 'dashboard', element: <DashboardPage /> },
        { path: 'network-overview', element: <NetworkOverview /> },
        { path: 'campaign-overview/:period', element: <CampaignOverview /> },
        { path: 'point-of-sales/:userId', element: <PointOfSaleDetails /> },
        { path: 'default', element: <MobileStatistics /> }
      ] },
      { path: '/product/:id/campaign', element: <ProductCampaign /> },
      {
        path: 'onboarding',
        element: <OnboardingPage />,
      },
      {
        path: 'orders/:id',
        element: <OrderDetailsPage />
      }
    ],
  },
  {
    path: 'products/:productId/order',
    element: <OrderPage />,
  },
  {
    path: '*',
    element: <Navigate to="/catalogs" />,
  },  
]
