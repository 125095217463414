import { useAuth0 } from "@auth0/auth0-react"
import { Sidebar } from "shared/elements"
import { Box, Button, Image, Text } from "grommet"
import { FormClose, Menu } from "grommet-icons"
import { Advertising, Analytics } from "libs/icons"
import { useState } from "react"
import { useSelector } from "react-redux"
import { Link, useMatch, useNavigate } from "react-router-dom"
import { useQuery } from "react-query"
import { getPointOfSalesHasMinManagerRole } from "api/visiretail/v2/point-of-sales"
import { StatusButton } from "shared"

type NavLinkProps = {
  to: string
  icon: any
  label: string
  onClick: (to: string) => void
}

const NavLink = ({ to, icon: Icon, label, onClick }: NavLinkProps) => {
  const isActive = useMatch(`${to}/*`)

  const background = isActive ? 'brand' : ''
  const color = isActive ? 'white' : 'dark-500'

  return (
    <Link to={to} style={{ height: '100%' }}>
      <Box
        onClick={() => onClick(to)}
        background={background}
        direction="row"
        gap="medium"
        align="center"
        pad="large"
        round="4px"
        fill
      >
        {Icon && <Icon color={color} />}
        <Text color={color}>{label}</Text>
      </Box>
    </Link>
  )
}

export const MobileMainNavigation = () => {
  const [showSidebar, setShowSidebar] = useState(false)
  const { data: pointOfSales } = useQuery(['point-of-sales', { minRole: 'manager' }], () => getPointOfSalesHasMinManagerRole(''))

  const { user, logout } = useAuth0()
  const { logoUrl } = useSelector((state: any) => state.theme)

  const navigate = useNavigate()
  const isOnboarding = useMatch('/onboarding/*')

  const handleClick = (to: string) => {
    navigate(to)
    setShowSidebar(false)
  }

  return (
    <>
      <Box 
        as="header"
        direction="row"
        elevation="small"
        justify="between"
        align="center"
        background="white"
        pad="medium"
        height="50px"
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 10,
        }}
      >
        <Box direction="row" align="center" gap="large">
          <Menu size="24px" onClick={() => setShowSidebar(true)} />
          <Box fill="vertical">
            <Link to="/" style={{ display: 'flex' }}>
              <Image src={logoUrl} alt="Brand logo" style={{ width: '130px' }} />
            </Link>
          </Box>
        </Box>
      </Box>
      <Sidebar open={showSidebar} onClose={() => setShowSidebar(false)}>
        <Box gap="xlarge" fill>
          <Box direction="row" justify="between" align="center">
            <Box>
              <Box
                justify="center"
                align="center"
                background="brand"
                width="42px"
                height="42px"
                round="50%"
              >
                <Text color="light-200">
                  {user?.name?.charAt(0).toUpperCase()}
                </Text>
              </Box>
            </Box>
            <FormClose onClick={() => setShowSidebar(false)} />
          </Box>
          <Box direction="column" justify="between" height="100%">
            <Box gap="medium">
              <NavLink
                to="/catalogs"
                icon={Advertising}
                label="Catalogues"
                onClick={handleClick}
              />
              <NavLink
                to="/analytics/default"
                icon={Analytics}
                label="Analytics"
                onClick={handleClick}
              />
            </Box>
            <Box gap="medium">
              {(!isOnboarding && pointOfSales?.data.some(p => !p.paymentMethod)) && (
                <StatusButton label="Compléter le profil" status="COMPLETE" fill="horizontal" onClick={() => handleClick('onboarding')} />
              )}
              <Button label="Se déconnecter" secondary onClick={() => {
                localStorage.removeItem('visiretail:onboarding')
                logout({ returnTo: window.location.origin })}
              } />
            </Box>
          </Box>
        </Box>
      </Sidebar>
    </>
  )
}