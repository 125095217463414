import React from 'react'
import { Box, Heading } from 'grommet'
import { withStatistics } from '../withStatistics'
import { DateNavBar } from './DateNavBar'
import { GlobalStatistics } from './GlobalStatistics'
import { DailyClickChartStatistics } from './DailyClickChartStatistics'
import { DetailedStatistics } from './DetailedStatistics'
import { TopPointOfSalesListStatistics } from './TopPointOfSalesListStatistics'

const Dashboard = () => (
  <Box direction="column-reverse" gap="xlarge">
    <Box gap="xlarge">
      <Box pad={{ horizontal: 'large' }} className="title">
        <Heading level="6" fill>
          Statistiques Globales
        </Heading>
        <GlobalStatistics />
      </Box>

      <DailyClickChartStatistics forUser={'me'} />

      <Box pad="large">
        <TopPointOfSalesListStatistics />
      </Box>

      <Box pad="large">
        <DetailedStatistics forUser={'me'} />
      </Box>
    </Box>

    <DateNavBar />
  </Box>
)

export default withStatistics(Dashboard)
