export const OLD_API_URL =
  process.env.REACT_APP_ENV === 'production' ? 'https://api.visiretail.io' : 'https://api.visiretail.dev'

export const API_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://rest-api-v1-e324ax6fpa-ew.a.run.app'
    : 'https://visiretail-api-i5pw4qjhwa-ew.a.run.app'

export const AUTH_API_URL =
  process.env.REACT_APP_ENV === 'production' ? 'https://auth.secure-vp.com' : 'https://auth.secure-vp-dev.com'

export const WHITE_LABEL_API_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://white-labeling-api.visiperf.io'
    : 'https://white-labeling-api.visiperf.dev'

export const LEAD_API_URL =
  process.env.REACT_APP_ENV === 'production' ? 'https://rest-api-v1-5peuy255uq-ew.a.run.app' : 'https://lead-api.visiperf.dev'

export const AUTH0_CLIENT_ID = 
  process.env.REACT_APP_ENV === "production" ? "AFSicZd0fhlLVSdL7dpWdldhOWdV9Gqb" : "ljE6FLuMk1WB6pk6LBW9kQ62EB3trRnH"

export const AUTH0_DOMAIN = 
  process.env.REACT_APP_ENV === "production" ? "https://auth.visiperf.io" : "https://auth.visiperf.dev"

export const AUHT0_AUDIENCE = 
  process.env.REACT_APP_ENV === "production" ? "https://api.visiretail.io" : "https://api.visiretail.dev"

export const AUTH0_DEFAULT_SCOPE = "email profile visiretail:access"

export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_ENV === 'production' ? 'pk_live_51IKK4EEkB2PDfXBDhyOgHXtYg5TkLfq8uL4afhrWEbyq5nua4B2JlFTQ1Je2fxelyY7erjpGFDHMk9evF57wD3lF00BVehzSBH' : 'pk_test_51Klu3kF0GIdby4mdRxFOBs2PdGByOc22VKaN1wAD3yUS7AUw8bP19b56apWLdTP1vZv1dQC3EyjORqo3LOVASlmT00Eu2RoCvV'

export const HOTJAR_SITE_ID = process.env.REACT_APP_ENV === 'production' ? 2676563 : 2665322

export const HOTJAR_SNIPPET_VERSION = process.env.REACT_APP_ENV === 'production' ? 6 : 6

export const MIXPANEL_TOKEN =
  process.env.REACT_APP_ENV === 'production' ? '2853af5bc9e898ecab8ce4b1842527ae' : 'b5e5ca2eca3ff6319ac30b43aaa1f2d0'

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_ENV === 'production' ? 'AIzaSyA8MXQKgAdG_ajpzFiAaMGD0qFHQk972v8' : 'AIzaSyA7sr-Ngk54xpSAbezvV4FN6vgP1nj5Unc',
    projectId: process.env.REACT_APP_ENV === 'production' ? 'visiretail-project' : 'dev-visiretail-project'
}