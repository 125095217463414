import React, { Children, useState } from 'react'
import { Box } from 'grommet'

import { ChoiceContext } from './ChoiceContext'

export const Choices = ({ children, value, onChange }) => {
  const [selectedChoice, setSelectedChoice] = useState(value)

  const handleSelectChoice = index => {
    setSelectedChoice(index)
    onChange(index)
  }

  return (
    <Box gap="small">
      {Children.map(children, (child, index) => (
        <ChoiceContext.Provider
          value={{
            isSelected: selectedChoice === index+1,
            onSelect: () => handleSelectChoice(index+1),
          }}
        >
          {child}
        </ChoiceContext.Provider>
      ))}
    </Box>
  )
}
