import React, { useContext } from 'react'
import { Box, RadioButton, ResponsiveContext, Paragraph, Text } from 'grommet'
import { isString } from 'lodash/fp'

import { ChoiceContext } from './ChoiceContext'
import { ChoicePannelContent } from './ChoicePannelContent'
import { Divider } from '..'

export const ChoicePannel = ({ children, label, labelDetail, description, defaultOpen, disabled, ...props }) => {
  const { isSelected, onSelect } = useContext(ChoiceContext)
  const size = useContext(ResponsiveContext)

  return (
    <Box gap={size === 'small' ? 'large' : 'medium'} fill {...props}>
      <Box gap="small">
        <Box direction="row" align="center" justify="between">
          <RadioButton
            name="choice"
            label={
              <Text size="small" weight="bold" color={disabled ? '#c8c8c8' : ''}>
                {label}
              </Text>
            }
            checked={isSelected}
            onChange={onSelect}
            disabled={disabled}
          />
          {labelDetail && isString(labelDetail) ? (
            <Text size="small" weight="bold" color={disabled ? '#c8c8c8' : ''}>
              {labelDetail}
            </Text>
          ) : (
            labelDetail
          )}
        </Box>
        {description && (
          <ChoicePannelContent>
            {isString(description) ? (
              <Paragraph size="small" fill color={disabled ? '#c8c8c8' : ''}>
                {description}
              </Paragraph>
            ) : (
              description
            )}
          </ChoicePannelContent>
        )}
      </Box>
      {children && (defaultOpen || isSelected) && (
        <ChoicePannelContent animation={!defaultOpen ? ['fadeIn', 'slideDown'] : null}>
          {size !== 'small' && <Divider />}
          {children}
        </ChoicePannelContent>
      )}
    </Box>
  )
}

ChoicePannel.defaultProps = {
  background: 'light-1',
  pad: { vertical: 'large', horizontal: 'medium' },
  round: '5px',
}
